import { Config } from '../Config';
import { GetTokenValue } from '../Token';

const getBaseRequest = (headers: HeadersInit = {}): RequestInit => ({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Config.foundationBearer}`,
        ...GetTokenValue(),
        ...headers
    },
    credentials: 'include'
});

const handleResponse = (response:any) => response.text().then((text:string) => {

    const data = text && JSON.parse(text);

    if (!response.ok) {
        // const error = (data && data.message) || response.statusText;
        return Promise.reject(data.code);
    }

    return data;
});

export const get = (url:string) => {
    const requestOptions: RequestInit = {
        method: 'GET',
        ...getBaseRequest()
    };

    return fetch(url, requestOptions).then(handleResponse);
};

export const post = (url: string, body: object) => {

    const requestOptions: RequestInit = {
        method: 'POST',
        ...getBaseRequest(),
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions).then(handleResponse);
};

export const put = (url: string, body: object) => {
    const requestOptions: RequestInit = {
        method: 'PUT',
        ...getBaseRequest(),
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
};