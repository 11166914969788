import React from 'react';

import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import Landscape from '../../UI/Landscape/Landscape';
import Heading from '../../UI/Heading/Heading';

import style from '../Loader.module.scss';

interface CardProps {
    title?: string;
}

const Card = ({title}:CardProps) => {
    return <div className={style.CardLoader}>
        <div className={style.Sky}>
            {[0,1,2,3,4].map(key => {
                return <div key={'cl-'+key} className={[style.Cloud,style['Cloud--'+key]].join(' ')}></div>
            })}
        </div>
        <Grid>
            <GridColumn size={6}>
                <div className={style.CardLoader__Body}>
                    <div className={style.Spinner}></div>
                    <Heading size="6" color="secondary">{title || 'Tu contenido se está cargando...'}</Heading>
                </div> 
            </GridColumn>
        </Grid>
    </div>
}

export default Card;