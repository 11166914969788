import React from 'react';
import { ColorTokens } from '../../../Models/Theme';
import style from './Heading.module.scss';

interface HeadingProps {
    color?: ColorTokens
    size: '1' | '2' | '3' | '4' | '5' | '6'
}

const CSS_PREFIX:string = 'Heading--';

const Heading: React.FC<HeadingProps> = ({color = 'base', size, children}) => {

    const styleConfig = [style.Heading,style[CSS_PREFIX+color]].join(' ');
    let heading = null;
    
    switch(size) {
        case '1': 
            heading = <h1 className={styleConfig}>{children}</h1>;
            break;
        case '2': 
            heading = <h2 className={styleConfig}>{children}</h2>;
            break;
        case '3': 
            heading = <h3 className={styleConfig}>{children}</h3>;
            break;
        case '4': 
            heading = <h4 className={styleConfig}>{children}</h4>;
            break;
        case '5': 
            heading = <h5 className={styleConfig}>{children}</h5>;
            break;
        case '6': 
            heading = <h6 className={styleConfig}>{children}</h6>;
            break;
    }

    return heading;
}

export default Heading;