import React, { useState, useEffect } from 'react';

import { User } from '../Models/User';
import { API } from '../Utils/API/API';
import { SaveToken, DeleteToken } from '../Utils/Token';
import { ModelNames } from '../Utils/Helpers';

const UserContext = React.createContext({
    token: false,
    isSignedIn: false,
    signIn: (authToken: string) => {},
    signOut: () => {},
    profile: {
        name: '',
        email: undefined,
        data: {
            phoneNumber: undefined,
            itinerary: [],
            transport: [],
            channels: [],
            filters: []
        }
    },
    updateProfile: (newProfile:any) => {},
    updateProfileData: (key:string, data:any) => {},
    getProfile: () => {}
});

export const UserContextProvider: React.FC = props => {

    const [token, setToken] = useState<boolean>(false); 
    const [profile, setProfile] = useState<User>({
        name: '',
        email: undefined,
        data: {
            phoneNumber: undefined,
            itinerary: [],
            transport: [],
            channels: [],
            filters: []
        }
    } as User);
    
    const isSignedIn: boolean = token;

    const getProfileHandler = async () => {
        try {
            const request = await API.user.profile();
            updateProfileHandler(normalizeProfile(request));
        }
        catch(error) {
            setToken(false);
            DeleteToken();
        }
    }

    useEffect(() => {
        getProfileHandler();
    },[]);

    const signInHandler = (authToken: string) => {
        SaveToken(authToken);
        setToken(true);
        getProfileHandler();
    }

    const signOutHandler = () => {
        DeleteToken();
        setToken(false);
        setProfile({...contextValue.profile});
    }

    const normalizeProfile = (savedProfile:any) => {

        const nProfile = {...savedProfile};

        nProfile.data.channels = (savedProfile.data.communication) ? savedProfile.data.communication.channels : ['email'];
        nProfile.data.filters = (savedProfile.data.communication) ? savedProfile.data.communication.contentFilters : [...ModelNames()];
        nProfile.data.communication = {};
        
        return nProfile;
    }

    const updateProfileHandler = (newProfile:any) => {
        
        const clonedProfile = {...profile};

        clonedProfile.name = newProfile.firstName;
        clonedProfile.email = newProfile.email;
        clonedProfile.data = newProfile.data;
        
        setProfile(clonedProfile);
    }

    const updateProfileDataHandler = (key:string, data:any) => {

        if(data === undefined) return;

        const clonedProfile = {...profile};

        clonedProfile.data[key] = data;
        setProfile(clonedProfile);
    }

    const contextValue = {
        token: token,
        isSignedIn: isSignedIn,
        signIn: signInHandler,
        signOut: signOutHandler,
        profile: profile,
        getProfile: getProfileHandler,
        updateProfile: updateProfileHandler,
        updateProfileData: updateProfileDataHandler
    }

    return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>;
}

export default UserContext;