import React, { useContext } from 'react';

import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Paths } from '../Utils/Helpers';
import { GetTokenValue } from '../Utils/Token';

import UserContext from '../Contexts/User';
import ScreenHome from './Home/Home';
import ScreenOnboarding from './Onboarding/Onboarding';
import ScreenUser from './User/User';
import ScreenAccess from './Access/Access';
import ScreenError from './Error/Error';
import ScreenInbox from './Inbox/Inbox';

const App = () => {

    const { token } = useContext(UserContext);

    return <div className="App">
        <BrowserRouter>
            <Switch>
                <Route exact path={Paths.home}>
                    <ScreenHome />
                </Route>
                <Route path={Paths.signIn}>
                    {(!GetTokenValue()) ? <ScreenAccess /> : <Redirect to={Paths.profile.account} />}   
                </Route>
                <Route path={Paths.signUp}>
                    {(!GetTokenValue()) ? <ScreenAccess /> : <ScreenOnboarding />}   
                </Route>
                <Route path={Paths.profile.account}>
                    {(!GetTokenValue()) ? <Redirect to={Paths.signIn} /> : <ScreenUser />}   
                </Route>
                <Route exact path={Paths.inbox}>
                    <ScreenInbox />
                </Route>
                <Route path={Paths.content}>
                    <ScreenHome />
                </Route>
                <Route path="*">
                    <ScreenError />
                </Route>
            </Switch>
        </BrowserRouter>
    </div>
}

export default App;