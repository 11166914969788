import React from 'react';
import { DigitInTwo } from '../../Utils/Helpers';

import Icon from '../UI/Icon/Icon';
import style from './Chat.module.scss';
import Heading from '../UI/Heading/Heading';

const Chat = () => {
    
    const date = new Date();
    const amPm = date.getHours() > 12 ? 'pm' : 'am';
    const now = `${DigitInTwo(date.getHours())}:${DigitInTwo(date.getMinutes())}`;

    return <div className={style.Chat}>
        <div className={style.Chat__Arrow}></div>
        <div className={style.Chat__Wrapper}>
            <Icon name="clock" color="neutral" type="outline" />
            <Heading size="4" color="neutral">
                Tu mapa de movilidad a las {now}
            </Heading>
        </div>
    </div>;
}

export default Chat;