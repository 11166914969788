import React, { Fragment } from 'react';
import { LandscapeTokens } from '../../../Models/Theme';
import style from './Landscape.module.scss';

interface LandscapeProps {
    type: LandscapeTokens;
    clouds?: boolean
}

const Landscape = ({type, clouds = false}:LandscapeProps) => {
    return <div className={[style.Landscape,style['Landscape--'+type]].join(' ')}>
        {(clouds) && 
            <Fragment>
                <div className={style.Sky}></div>
                {[0,1,2,3,4].map(key => {
                    return <div key={'cl-'+key} className={[style.Cloud,style['Cloud--'+key],style['Cloud--'+key+'--on']].join(' ')}></div>
                })}
            </Fragment>}
    </div>
}

export default Landscape; 