import React, { Fragment } from 'react';

import { NologisContent, AlternativeContent } from '../../../Models/Content';

import Heading from '../../UI/Heading/Heading';
import Divider from '../../UI/Divider/Divider';
import Icon from '../../UI/Icon/Icon';

import style from '../Content.module.scss';

interface AlternativeProps {
    icon: string
    meta: NologisContent
    info: AlternativeContent
    expanded?: boolean
}

const Alternative = ({meta, info, icon, expanded = false}: AlternativeProps) => {
    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Alternativa</Heading>
            <p>{meta.title}</p>
        </header>
        {(expanded) && <div className={style.Content__Body}>           
            {/* Note */}
            {info.description && <Fragment>
                <Heading size="6" color="base"><Icon name="map-signs" type="solid" /> Ruta</Heading>
                <Divider size="x-small" /> 
                <p>{info.description}</p>
            </Fragment>}
        </div>}
    </Fragment>;
}

export default Alternative;