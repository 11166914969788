import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { API } from '../../../Utils/API/API';
import { Paths } from '../../../Utils/Helpers';

import UserContext from '../../../Contexts/User';
import HeaderFlow from '../../UI/Header/Flow/Flow';
import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import Divider from '../../UI/Divider/Divider';
import Button from '../../UI/Button/Button';
import Heading from '../../UI/Heading/Heading';
import Dialog from '../../Dialog/Dialog';
import Loader from '../../Loader/Loader';
import FooterSection from '../../UI/Footer/Section/Section';

const Privacy = () => {

    const history = useHistory();
    const { signOut } = useContext(UserContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false);
    const [confirmation, setConfirmation] = useState<boolean>(false);

    const savePrivacy = () => {
    }    

    const deleteAccount = async () => {   
        try {
            await API.user.deleteProfile();      
            setLoading(false);
            setConfirmation(true); 
        }
        catch(error) {
            setLoading(false);
            setServiceError(true);
        }
    }

    const confirmAccountDeletion = () => {
        setLoading(true);
        deleteAccount();
    }

    const deleteSession = () => {
        signOut();
        history.push(Paths.home);
    }

    return <div className="Privacy">
        <HeaderFlow 
            title="Tu privacidad" 
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="ocean" 
            icon="bars"
            goTo={() => history.goBack() } /> 
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="medium" />
                <Heading size="3" color="base">
                    Como asistente quiero proteger tus datos lo máximo posible por lo que nunca los usaré más allá de informarte acerca de la movilidad de la ciudad.
                </Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6}>
                <Heading size="3" color="base">Términos y condiciones</Heading>
                <Divider />
                <Button click={() => window.open(Paths.terms,'_blank')} color="base">Leer</Button>
            </GridColumn>
        </Grid>
        {/* <Grid>
            <GridColumn size={6}>
                <Heading size="3" color="base">Recupera tus datos</Heading>
                <Divider />
                <Button click={savePrivacy} color="base">Recuperar</Button>
            </GridColumn>
        </Grid> */}
        <Grid>
            <GridColumn size={6}>
                <Heading size="3" color="error">Borra tu cuenta</Heading>
                <Divider />
                <Button click={() => setConfirmDeletion(true)} color="error" elevation={true}>Borrar</Button>
            </GridColumn>
        </Grid>
        <FooterSection></FooterSection>
        {(confirmDeletion) && <Dialog 
            title="Borrar cuenta" 
            body="¿Confirmas que quieres borrar tu cuenta? Si aceptas borraré toda tu información del sistema..." 
            type="confirmation"
            closeColor="success"
            acceptColor="error"
            acceptAction={confirmAccountDeletion}
            close={() => setConfirmDeletion(false)} 
        />}
        {(serviceError) && <Dialog 
            title="Error" 
            body="No he podido procesar tu petición, inténtalo en unos minutos..." 
            type="confirmation" 
            close={() => setServiceError(false)} 
        />}
        {(loading) && <Loader 
            body="Un momento por favor, estoy borrando tu información de la base de datos..." 
            animation={false} 
        />}
        {(confirmation) && <Loader 
            title="Cuenta borrada" 
            body="Todos tus datos han sido borrados, ahora sé que no te encaja pero en el futuro espero volver a ser tu asistente. Muchas gracias." 
            animation={false} 
            buttonTitle="Aceptar" 
            buttonAction={deleteSession}
        />}
    </div>
}

export default Privacy;