import React, { useEffect } from 'react';
import style from './Section.module.scss';

interface props {
    position?: 'sticky';
} 

const Section: React.FC<props> = props => {

    const sectionStyle = (!props.position) ? [style.Footer] : [style.Footer,style['Footer--'+props.position]];
    
    useEffect(() => {
    },[])

    return <div className={sectionStyle.join(' ')}>
        {props.children}
    </div>
}

export default Section;