interface ConfigType {
    foundationBasePath: string
    foundationBearer: string
    webVersion: string
    venueId: string
}
  
// export const Config: ConfigType = {
//     foundationBasePath: process.env.NEXT_PUBLIC_NOLOGIS_BASE_URL || '',
//     foundationBearer: process.env.NEXT_PUBLIC_NOLOGIS_BEARER || ''
// };

export const StageConfig: ConfigType = {
    foundationBasePath: 'https://foundation-stage.nologis.com',
    foundationBearer: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJxVG45eUhFWEJBOEVHa0hpNTI2Wm5WbTB0Y25GdFNoeSIsImNsaWVudF9hbGlhcyI6ImxwZ2MtbW92aWxpZGFkIiwiY29uc3VtZXJfaWQiOiJmOWU2ODUzNi1hYWMyLTRhMzEtYTk4OC1jM2RmYmQ1YjJkYzcifQ.E8aB6S9pCZzU16fO6yOKIXCU8F3nlTFgrqaNRWzhJTI',
    webVersion: '2.0.0',
    venueId: '9a9a61a5-559c-4c02-816b-d97a5f0fc64e'
};

export const ProdConfig: ConfigType = {
    foundationBasePath: 'https://foundation.nologis.com',
    foundationBearer: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJNMjZMb2NENnU2ZDh0a052WFlpTU5Ic1dLMXhwVEQ2aSIsImNsaWVudF9hbGlhcyI6ImxwZ2MtbW92aWxpZGFkIiwiY29uc3VtZXJfaWQiOiI0NTBkNzA2Mi1iNjU0LTRlZmYtYTE3MC1jZjM2Yjc2YmQ3YzkifQ.0JTYoJZ-ZfrYPqSmFPnxjVchp13cUVGeJsXIpqh5r04',
    webVersion: '2.0.0',
    venueId: 'ee1754ba-6135-4d1e-b81f-5818fc68460d'
};



export const Config: ConfigType = {...ProdConfig};


