import React, { useEffect } from 'react';

import { Config } from '../../Utils/Config';
import { useHistory } from 'react-router-dom';
import { APIDefinition, ContentClickEvent, MapEventObserver, ViewerMap, CenterAtDataEntity, MapActionDispatcher } from '@nologis/maps';

import style from './Map.module.scss';
import MapToolbar from '../../Components/Map/Toolbar/Toolbar';

// TO DO: It's duplicated in helpers
const dataEntityNames = ['obra', 'incidencia', 'parquimetro', 'event', 'alternative', 'block', 'bus', 'bus-line-incidence', 'desvio-de-linea', 'linea', 'carril-bici', 'estacion-bici'];

export const getApiDefinition = (): APIDefinition => ({
    baseURL: Config.foundationBasePath,
    bearer: Config.foundationBearer
});

const tilesConfig = {
    type: 'osm',
    osm: {
        url: 'https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=53d7c8c8e4e64f6692edb92ca696c7df',
        opacity: 1
    }
};

const themeConfig = {
    loading: {
        backgroundColor: '#F7F3E3',
        dotColor: '#333333'
    }
};

const Map = () => {

    const apiDefinition = getApiDefinition();
    const history = useHistory();

    useEffect(() => {

        const handleContentClick = (event: ContentClickEvent) => {
            history.push(`/tu-mapa/${event.content.client_model_id}/${event.content.id}`);
            MapActionDispatcher.dispatch(new CenterAtDataEntity(event.content.id,event.content.client_model));
        }

        MapEventObserver.on('content:click', handleContentClick);

        return () => {
            MapEventObserver.off('content:click', handleContentClick);
        }
    }, []);

    return <div className={style.Map}>
        <ViewerMap
            apiDefinition={apiDefinition}
            language="es"
            venueContextId={Config.venueId}
            dataEntityNames={dataEntityNames}
            canMouseWheelZoom={true}
            canPan={true}
            hideFullScreenControl={true}
            type="default"
            tiles={{
                type: 'osm',
                osm: {
                    url: 'https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=53d7c8c8e4e64f6692edb92ca696c7df',
                    opacity: 1
                }
            }}
            theme={themeConfig}
            hideControls={false}
            viewport="desktop" />
        <MapToolbar/>
    </div>;
}

export default Map;
