import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { UserItineraryPoint } from '../../../Models/User';
import { API } from '../../../Utils/API/API';
import { Paths } from '../../../Utils/Helpers';

import UserContext from '../../../Contexts/User';
import Sheet from '../../Sheet/Sheet';
import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import HeaderFlow from '../../UI/Header/Flow/Flow';
import FooterSection from '../../UI/Footer/Section/Section';
import Divider from '../../UI/Divider/Divider';
import Button from '../../UI/Button/Button';
import Link from '../../UI/Link/Link';
import Icon from '../../UI/Icon/Icon';
import Dialog from '../../Dialog/Dialog';
import ItineraryPointNew from './Point/New/New';
import ItineraryPoint from './Point/Inline/Inline';
import Loader from '../../Loader/Loader';
import Heading from '../../UI/Heading/Heading';

import ItineraryImage from '../../../Assets/itinerary.svg';

interface ItineraryProps {
    showHeader?:boolean
    ctaCopy?:string
}

const Itinerary: React.FC<ItineraryProps> = ({showHeader = true, ctaCopy = 'Guardar'}) => {

    const history = useHistory();
    const { profile, updateProfileData, isSignedIn } = useContext(UserContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [newPoint, setNewPoint] = useState<boolean>(false);
    const [userPoints, setUserPoints] = useState<UserItineraryPoint[]>([]);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [readyToInteract, setReadyToInteract] = useState<boolean>(false);

    const saveItinerary = async () => {
        try {
            const updatedProfile = await API.user.updateProfile({
                data: { 
                    'itinerary': [...userPoints]
                }
            });
            // update profile
            updateProfileData('itinerary',[...updatedProfile.data.itinerary]);
            // move to transports if user is going through onboarding
            //if(!showHeader) setLoading(true);
        }
        catch(error) {
            setServiceError(true);
        }
    }

    const addNewPoint = (show:boolean) => setNewPoint(show);
    const discardNewPoint = () => setNewPoint(false);

    const savePoint = (point:UserItineraryPoint) => {

        const itinerary = [...userPoints];

        itinerary.push(point);
        setUserPoints(itinerary);
        setNewPoint(false);
    }
    
    const deletePoint = (index:number) => {

        const itinerary = [...userPoints];
        
        itinerary.splice(index,1);
        setUserPoints(itinerary);
    }

    useEffect(() => {
        if(isSignedIn && !readyToInteract) {
            if(profile.data.itinerary) setUserPoints([...profile.data.itinerary]);
            setReadyToInteract(true);
        }
    },[profile]);

    useEffect(() => {
        if(readyToInteract) saveItinerary();
    },[userPoints]);

    return <div className="Itinerary">
        {(showHeader) && <HeaderFlow 
            title="Tu itinerario" 
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="ocean" 
            icon={(showHeader) ? 'bars': 'arrow-left'}
            goTo={() => history.goBack()} />}
        <Grid clearSide="bottom">
            <GridColumn size={6}>
                <Divider size="medium" />
                <Heading size="3" color="base">
                    Añade aquí los puntos por que te sueles mover a diario, así podré avisarte si alguno se ve afectado. 
                </Heading>
            </GridColumn>
        </Grid>
        <Grid clearSide="bottom">
            {(userPoints.length > 0) && <Fragment>
                <Divider size="small" />
                <GridColumn size={6}>
                    {userPoints.map((point:UserItineraryPoint, i:number) => {
                        return <ItineraryPoint key={'it-'+i} {...point} listIndex={i} remove={deletePoint} />
                    })}
                    <Button click={() => addNewPoint(true)} color="base" type="rounded" elevation={false}>
                        <Icon name="plus" />
                    </Button>
                </GridColumn> 
            </Fragment>}
        </Grid>
        <Grid>
            <GridColumn size={6}> 
                {(userPoints.length === 0) && <Fragment>
                    <img src={ItineraryImage} style={{'width':'100%'}} alt="Tu itinerario" />
                    <Divider size="large" />
                    <Button click={() => addNewPoint(true)} color="secondary">Añadir mi primer punto</Button>
                    {(!showHeader) && <Fragment>
                        <Divider size="small" />
                        <Link title="Quiero añadir mis puntos más tarde" click={() => setLoading(true)} />
                    </Fragment>}
                </Fragment>}
            </GridColumn>
        </Grid>
        {(!showHeader) && <FooterSection>
            <Grid clearSide="top">
                <GridColumn size={6}>
                    {(userPoints.length > 0) && <Fragment>
                        <Button click={() => setLoading(true)} color="secondary">{ctaCopy}</Button>
                    </Fragment>}
                </GridColumn>
            </Grid>
        </FooterSection>}
        <FooterSection></FooterSection>
        {(serviceError) && <Dialog title="Alerta" body="Parece que ha ocurrido un error guardando tus datos, inténtalo en unos minutos..." type="info" close={() => setServiceError(false)} />}
        {(newPoint) && <Sheet><ItineraryPointNew save={savePoint} cancel={discardNewPoint}/></Sheet>}
        {(loading) && <Loader title="¡Tu perfil está listo!" body="Estoy cargando tu mapa de movilidad, recuerda que si tienes cualquier duda puedes escribirme o visitar la ayuda." destination={Paths.home} timeToDestination={3000} animation={false} />}
    </div>
}

export default Itinerary;