import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Paths } from '../../Utils/Helpers';

import UserContext from '../../Contexts/User';
import Grid from '../../Components/UI/Grid/Grid';
import GridColumn from '../../Components/UI/Grid/Column/Column';
import Cookies from '../Cookies/Cookies'; 
import style from './Feed.module.scss';
import Card from '../UI/Card/Card';

interface FeedProps {
    selectedContent?:string
}

const LS_ALE_CTA:string = 'aleDeclinedAccount';

const Feed = ({selectedContent = ''}: FeedProps) => {
    
    const history = useHistory();
    const { isSignedIn } = useContext(UserContext);

    const [promptAccount,setPromptAccount] = useState<boolean>(false);
    const [declinedAccount,setAccountDeclining] = useState<boolean>(!!localStorage.getItem(LS_ALE_CTA));

    useEffect(() => {
        setTimeout(() => {
            if(!declinedAccount) setPromptAccount(true);
        },3000);
    },[])

    const declineAccount = () => {
        localStorage.setItem(LS_ALE_CTA,'true');
        setAccountDeclining(true);
    }

    return <div className={style.Feed}>
        <Grid toolbar={true}>
            <GridColumn toolbar={true}></GridColumn>
            <GridColumn size={5} clear={true} >
                {(!isSignedIn) && <Cookies />}
               
                {/* {(!isSignedIn && promptAccount && !declinedAccount) &&
                    <Card 
                        title="Crea tu cuenta" 
                        body="Crea tu cuenta ahora y te avisaré de lo que te puede afectar cuando te muevas por la ciudad." 
                        type="optional"
                        icon="user-circle"
                        clearPadding={true}
                        buttonColor="confirmation"
                        closeAction={() => declineAccount()}
                        primaryButtonLabel="Quiero crear mi cuenta" 
                        primaryButtonAction={() => history.push(Paths.signUp)}
                        secondaryButtonLabel="¿Ya tienes cuenta? Entra aquí"
                        secondaryButtonAction={() => history.push(Paths.signIn)} /> } */}
            </GridColumn>
        </Grid>       
    </div>
}

export default Feed;