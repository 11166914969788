import React from 'react';
import { ColorTokens } from '../../../Models/Theme';
import style from './Text.module.scss';

interface TextProps {
    color?: ColorTokens
    size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
}

const CSS_PREFIX:string = 'Text--';

const Text: React.FC<TextProps> = ({color = 'base', size, children}) => {

    const styleConfig = [style.Text,style[CSS_PREFIX+size]].join(' ');
    
    return <p className={styleConfig}>{children}</p>;
}

export default Text;