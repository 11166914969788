import React, { Fragment } from 'react';

import { ButtonColorTokens, CardColorTokens } from '../../Models/Theme';

import Backdrop from '../Backdrop/Backdrop';
import Card from '../../Components/UI/Card/Card';
import Button from '../../Components/UI/Button/Button';
import style from './Dialog.module.scss';
import Grid from '../UI/Grid/Grid';
import GridColumn from '../UI/Grid/Column/Column';

interface DialogProps {
    title: string
    body: string
    type: 'info' | 'confirmation'
    headerColor?: CardColorTokens
    closeColor?: ButtonColorTokens
    acceptColor?: ButtonColorTokens
    close: () => void
    acceptAction?: () => void
}

const Dialog = ({title, body, type, headerColor = 'alert', closeColor = 'secondary', acceptColor = 'secondary', close, acceptAction}: DialogProps) => {
    return <Backdrop>
        <div className={style.Dialog}>
            <Grid clearSide="bottom">
                <GridColumn size={6}>
                    <Card title={title} body={body} type={headerColor} icon="info-circle" clearPadding={false}>
                        {(type === 'confirmation') ? <Fragment>
                            <Button click={close} color={closeColor}>Cancelar</Button>
                            <Button click={acceptAction} color={acceptColor}>Aceptar</Button>
                        </Fragment> : <Button click={close} color={closeColor}>Aceptar</Button>}
                    </Card>
                </GridColumn>
            </Grid>
        </div>
    </Backdrop>;
}

export default Dialog;