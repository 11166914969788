import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from '../../../../Models/User';
import { API } from '../../../../Utils/API/API';
import { Paths } from '../../../../Utils/Helpers';

import Feedback from '../../../Feedback/Feedback';
import Grid from '../../../UI/Grid/Grid';
import GridColumn from '../../../UI/Grid/Column/Column';
import HeaderFlow from '../../../UI/Header/Flow/Flow';
import FooterSection from '../../../UI/Footer/Section/Section';
import FormInput from '../../../UI/Form/Input/Input';
import Divider from '../../../UI/Divider/Divider';
import Button from '../../../UI/Button/Button';
import Link from '../../../UI/Link/Link';
import Loader from '../../../Loader/Loader';
import Dialog from '../../../Dialog/Dialog';
import Heading from '../../../UI/Heading/Heading';

const Recover = () => {

    const history = useHistory();

    const [user, setUser] = useState<User>({email: '', clientAlias: 'lpgc-movilidad'} as User);
    const [loading, setLoading] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [infoIsValid, setInfoAsValid] = useState<boolean>(true);

    const setEmail = (email:string) => setUser({...user, email});
    
    const recoverPassword = async () => {
        try {
            await API.user.recoverPassword(user);
            setLoading(true);
        }
        catch(error) {
            setLoading(false);
            setServiceError(true);
        }
    }

    const validateRecover = () => {
        
        let isValid = (user.email.length > 0);

        // update
        setInfoAsValid(isValid);
        // submit info
        if(isValid) {
            setServiceError(false);
            recoverPassword();
        }
    } 

    return <div className="Recover">
        <HeaderFlow 
            title="Recuperar contraseña" 
            top="sunset" 
            bottom="primary" 
            subtitle="Acceso"
            steps="Ale, tu asistente de movilidad"
            avatar="happy" 
            avatarColor="sunset"
            goTo={() => history.goBack()} />
        {(serviceError) ? <Fragment><Feedback color="error">
                Parece que no encuentro tu correo en la base de datos, revísalo para continuar...
            </Feedback>
            <Divider size="small" />
        </Fragment> : 
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="medium" />
                <Heading size="3" color="base">
                    Introduce tu correo electrónico y te enviaré las instrucciones para crear una nueva contraseña...
                </Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>}
        <Grid>
            <GridColumn size={6}>
                <FormInput 
                    type="email"
                    label="Email"
                    required={true}
                    sublabel="Que usaste para crear tu cuenta" 
                    placeholder=""
                    valid={infoIsValid}
                    notifyValidation={setEmail}
                />
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={validateRecover} color="base">Enviar instrucciones</Button>
                    <Divider size="small" />
                    <Link title="Si ya tienes cuenta, puedes acceder aquí" click={() => history.push(Paths.signIn)} />
                </GridColumn>
            </Grid>
        </FooterSection>
        {(loading) ? <Loader title="Te acabo de enviar un correo!" body="Revisa tu buzón y sigue las instrucciones para crear tu nueva contraseña." destination={Paths.signIn} timeToDestination={2500} /> : null}
    </div>;
}

export default Recover;