import React from 'react';

import { ColorTokens } from '../../../Models/Theme';

import Icon from '../Icon/Icon';
import style from './Tag.module.scss';

interface TagProps {
    state?: boolean
    color?: ColorTokens
    customColor?: string
    customTextColor?: string
    icon?: string
    animate?: boolean,
    size?: 'x-small' | 'small' | 'medium'
}

const CSS_PREFIX = 'Tag--';

const Tag:React.FC<TagProps> = ({state = false, icon, color = 'base', customColor, customTextColor, animate = false, size = 'medium', children}) => {

    const styleConfig: string[] = [style.Tag,style[CSS_PREFIX+color],style[CSS_PREFIX+size]];

    if(animate) {
        styleConfig.push(style[CSS_PREFIX+'blinking']);
    }
    
    return <div className={styleConfig.join(' ')} style={(customColor) ? {'backgroundColor': customColor} : {}}>
        {(icon) && <Icon name={icon} />}
        <span style={(customTextColor) ? {'color': customTextColor} : {}}>{children}</span>
    </div>;
}

export default Tag;