import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { Paths } from '../../Utils/Helpers';

import OnboardingSteps from '../../Components/User/Onboarding/Steps';
import UserSignUp from '../../Components/User/Account/SignUp/SignUp';

const Onboarding = () => {
    return <div className="Onboarding">
        <Switch>
            <Route path={Paths.onboarding.channels}>
                <OnboardingSteps name="channels" />    
            </Route>
            <Route path={Paths.onboarding.transports}>
                <OnboardingSteps name="transport" />
            </Route>
            <Route path={Paths.onboarding.itinerary}>
                <OnboardingSteps name="itinerary" />
            </Route>
            <Route path={Paths.signUp}>
                <UserSignUp />
            </Route>
        </Switch>
    </div>;
}

export default Onboarding;