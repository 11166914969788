import React, { useState, useEffect } from 'react';
import Icon from '../../Icon/Icon';
import Switch from '../../Switch/Switch';
import style from './Input.module.scss';

interface InputProps {
    type: 'email' | 'password' | 'text' | 'tel' | 'checkbox' | 'time'
    label: string
    required: boolean
    placeholder: string
    sublabel: string
    value?: any
    disabled?: boolean
    icon?: string
    info?: boolean
    sublabelLink?: string
    valid?: boolean
    formatter?: 'phone' | 'time' | 'retype'
    referenceValue?: any
    notifyValidation?: (value:any) => void
    showInfo?: (value:boolean) => void
}

interface ValidationModel {
    style: string
    message: string
}

const FEEDBACK_STATES = {
    empty: {
        style: 'Input--error',
        message: 'El campo no puede estar vacío'
    },
    reviewFormat: {
        style: 'Input--error',
        message: 'Revisa el formato del campo'
    },
    minLength: {
        style: 'Input--error',
        message: 'Debe tener al menos 6 caracteres'
    },
    unselected: {
        style: 'Input--error',
        message: 'Debes seleccionar este campo para continuar'
    },
    correct: {
        style: 'Input--success',
        message: 'Este campo está listo'
    },
    retype: {
        style: 'Input--error',
        message: 'La contraseña no coincide'
    },
    time: {
        style: 'Input--error',
        message: 'El formato debe ser HH:MM, por ejemplo 09:30'
    },
    focus: {
        style: 'Input--focus',
        message: ''
    }
}

const EXPS = {
    email: new RegExp('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'),
    phone: new RegExp('^[0-9]{1,9}$'),
    time: new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')
}

const Input = ({type, label, required, placeholder, sublabel, value = '', disabled, icon, info, sublabelLink, valid = true, formatter, notifyValidation, showInfo, referenceValue}:InputProps) => {

    const [newValue,setNewValue] = useState<any>(value);
    const [dirty,setDirty] = useState<boolean>(false);
    const [validation, setValidation] = useState<ValidationModel>({
        style: '',
        message: sublabel
    } as ValidationModel);

    const externalValidation: boolean = valid;
    const styleConfig: string[] = [style.Input,validation.style];
    
    useEffect(()=> {
        (dirty || !valid) ? validate(newValue) : setDirty(true);
    },[newValue,externalValidation])

    useEffect(()=> {
        if(value !== newValue) {
            setNewValue(value);
        }
    },[value])

    const validate = (value:any) => {

        let isValid = true;
        let feedback = {...validation};

        // do not validate in case it's not required
        if(!required && notifyValidation) {
            notifyValidation(value);
            return;
        }

        // global: aims to control global errors as field is empty
        if(value === '' && type !== 'checkbox') {
            setValidation({
                style: style[FEEDBACK_STATES.empty.style],
                message: FEEDBACK_STATES.empty.message
            });
            return;
        }

        // specific: aims to control per input type
        switch(type) {
            case 'text':
                if(formatter === 'time') {
                    isValid = EXPS.time.test(value);
                    if(!isValid) {
                        feedback.style = style[FEEDBACK_STATES.time.style];
                        feedback.message = FEEDBACK_STATES.time.message;
                    }
                }
                break;
            case 'email': 
                
                isValid = EXPS.email.test(value);

                if(!isValid) {
                    feedback.style = style[FEEDBACK_STATES.reviewFormat.style];
                    feedback.message = FEEDBACK_STATES.reviewFormat.message;
                }
                break;
            case 'tel':
                
                isValid = EXPS.phone.test(value) && value.length === 9;

                if(!isValid) {
                    feedback.style = style[FEEDBACK_STATES.reviewFormat.style];
                    feedback.message = FEEDBACK_STATES.reviewFormat.message;
                }
                break; 
            case 'password':

                isValid = (value.length >= 6);
                
                if(!isValid) {
                    feedback.style = style[FEEDBACK_STATES.minLength.style];
                    feedback.message = FEEDBACK_STATES.minLength.message;
                }

                // just for retyping

                if(formatter === 'retype' && referenceValue) {
                    isValid = (value === referenceValue);
                    if(!isValid) {
                        feedback.style = style[FEEDBACK_STATES.retype.style];
                        feedback.message = FEEDBACK_STATES.retype.message;
                    }
                }

                break;
            case 'checkbox':

                isValid = value;

                if(!isValid && dirty) {
                    feedback.style = style[FEEDBACK_STATES.unselected.style];
                    feedback.message = FEEDBACK_STATES.unselected.message;
                }
                break;
        }
        
        // manage error or move forward
        if(isValid) { 
            setValidation({
                style: '',
                message: (!sublabelLink) ? FEEDBACK_STATES.correct.message : sublabel
            });

            // notify value in case is valid, this applies just for required fields
            if(notifyValidation && value) {
                notifyValidation(value);
            }
        }
        else {
            setValidation(feedback);

            if(notifyValidation) {
                notifyValidation(value);
            }
        }
    }

    const displayInfo = () => {
        if(showInfo) showInfo(true);
    }

    const changeHandler = (event: any) => {
        setNewValue(event.target.value);
    }

    const keyHandler = (event: any) => {
        switch(formatter) {
            case 'time':
                break; 
            case 'retype':
                break; 
        }
    }

    return <div className={styleConfig.join(' ')}>
        <label> 
            <h3>
                {(icon) ? <Icon name={icon} /> : null}
                {label} 
                {(info) ? <div onClick={displayInfo}><Icon name="info-circle" /></div> : null}
            </h3>
            <div className={style.Input__sublabel}>
                {(sublabel) ? 
                    <span>
                        {validation.message}
                        {(sublabelLink) ? <a href={sublabelLink}>aquí</a> : null}
                    </span> 
                : null }
            </div>
            {(type !== 'checkbox') ? <input type={type} name="input" placeholder={placeholder} onChange={(event) => changeHandler(event)} onBlur={(event) => validate(event.target.value)} onKeyUp={(event) => keyHandler(event)} value={newValue} disabled={disabled} />
                : <Switch label={placeholder} notify={validate} active={false} color="success" labelSize="label-small" /> 
            }
        </label>
    </div>
}

export default Input;