import React from 'react';
import style from '../Grid.module.scss';

interface ColumnProps {
    toolbar?: boolean;
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    flex?: boolean;
    clear?: boolean;
}

const Column: React.FC<ColumnProps> = ({toolbar = false, size = 1, flex = true, clear = false, children}) => {

    const CSS_PREFIX = 'Grid__column--';
    const CSS_CLEAR = (clear) ? 'clear' : '';
    const CSS_FLEX= (flex) ? 'flex' : '';
    const CSS_TOOLBAR= (toolbar) ? 'toolbar' : '';

    const styleConfig = [style.Grid__column,style[CSS_PREFIX+'x'+size],style[CSS_PREFIX+CSS_FLEX],style[CSS_PREFIX+CSS_CLEAR],style[CSS_PREFIX+CSS_TOOLBAR]];

    return <div className={styleConfig.join(' ')}>
        {children}
    </div>;
}

export default Column;