import React from 'react';

import Grid from '../../Grid/Grid';
import GridColumn from '../../Grid/Column/Column';
import Search from '../../../Search/Search';
import Chat from '../../../Chat/Chat';
import Avatar from '../../Avatar/Avatar';
import style from './Global.module.scss';
import Button from '../../Button/Button';

interface GlobalProps{
    navigationClick?: () => void;
}

const Global = ({navigationClick}:GlobalProps) => {
    return <div className={style.Header}>
        <Grid row={true}>
            <GridColumn toolbar={true} size={2} clear={true}>
                <Button click={navigationClick} type="wrapper">
                    <Avatar elevation={true} color="sunset" />
                </Button>
            </GridColumn>
            <GridColumn size={5} clear={true}>
                <Chat /> 
            </GridColumn>
        </Grid>
    </div>
}

export default Global;