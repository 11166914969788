import React, { useState} from 'react';
import { useParams } from 'react-router-dom';

import HeaderGlobal from '../../Components/UI/Header/Global/Global';
import Map from '../../Components/Map/Map';
import Feed from '../../Components/Feed/Feed';
import Content from '../../Components/Content/Content';
import Navigation from '../../Components/Navigation/Navigation';

interface UrlProps {
    modelId: string
    contentId: string
}

const Home: React.FC = () => {

    const { modelId, contentId } = useParams<UrlProps>();
    const [openNav, setOpenNav] = useState<boolean>(false);
    
    const hasContentToRender = Boolean(modelId) && Boolean(contentId);

    const openNavigation = () => {
        setOpenNav(!openNav);
    }

    return <div className="Home">
        <Navigation open={openNav}>
            <HeaderGlobal navigationClick={openNavigation}/>
            <Map />
            <Feed />
            {hasContentToRender && <Content model={modelId} id={contentId}/>}
        </Navigation>
    </div>;
}

export default Home;
