import React from 'react';
import { useQuery } from 'react-query';
import { API } from '../Utils/API/API';

export const useContentDetails = (contentModel: string, contentId: string) => {

    const {isLoading, data} = useQuery(['content', contentModel, contentId], () => API.content.detail(contentModel, contentId));

    return {
        isLoading,
        content: data,
    }
};
