import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { UserTransport } from '../../../Models/User';
import { API } from '../../../Utils/API/API';
import { Paths, Parser } from '../../../Utils/Helpers';

import UserContext from '../../../Contexts/User';
import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import Switch from '../../UI/Switch/Switch';
import FooterSection from '../../UI/Footer/Section/Section';
import Divider from '../../UI/Divider/Divider';
import Button from '../../UI/Button/Button';
import Link from '../../UI/Link/Link';
import Heading from '../../UI/Heading/Heading';
import Dialog from '../../Dialog/Dialog';
import HeaderFlow from '../../UI/Header/Flow/Flow';

interface TransportProps {
    showHeader?:boolean
    ctaCopy?:string
}

const Transport = ({showHeader = true, ctaCopy = 'Guardar'}: TransportProps) => {

    const history = useHistory();
    const { profile, updateProfileData } = useContext(UserContext);

    const [transports, setTransports] = useState<UserTransport>({
        car: false,
        bus: false,
        motorcycle: false,
        walking: false,
        scooter: false,
        bike: false,
        taxi: false
    });

    const [screenInfo, setScreenInfo] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);

    const saveTransport = async () => {
        try {
            await API.user.updateProfile({ 
                data: {
                    'transport': Parser({...transports})
                }
            });
            // update profile
            updateProfileData('transport',Parser({...transports}));
            // move to next if user is going through onboarding or display saved
            (!showHeader) ? history.push(Paths.onboarding.itinerary) : setSaved(true);
            // remove saved on interaction
            document.onclick = () => setSaved(false);
        }
        catch(error) {
            setServiceError(true);
        }
    }

    const setCar = (car:boolean) => setTransports({...transports, car});
    const setMotorcycle = (motorcycle:boolean) => setTransports({...transports, motorcycle});
    const setBus = (bus:boolean) => setTransports({...transports, bus});
    const setWalking = (walking:boolean) => setTransports({...transports, walking});
    const setScooter = (scooter:boolean) => setTransports({...transports, scooter});
    const setTaxi = (taxi:boolean) => setTransports({...transports, taxi});
    const setBike = (bike:boolean) => setTransports({...transports, bike});
    const showScreenInfo = (display:boolean) => setScreenInfo(display);
    const showServiceError = (display:boolean) => setServiceError(display);

    useEffect(() => {    
        if(profile.data.transport && profile.data.transport.length > 0 ){

            const profileTransports = {...transports};

            profileTransports.car = (profile.data.transport.indexOf('car') !== -1);
            profileTransports.bus = (profile.data.transport.indexOf('bus') !== -1);
            profileTransports.motorcycle = (profile.data.transport.indexOf('motorcycle') !== -1);
            profileTransports.walking = (profile.data.transport.indexOf('walking') !== -1);
            profileTransports.scooter = (profile.data.transport.indexOf('scooter') !== -1);
            profileTransports.bike = (profile.data.transport.indexOf('bike') !== -1);

            setTransports(profileTransports);
        }
    },[profile]);

    return <div className="Transport">
        {(showHeader) ? 
            <HeaderFlow 
                title="Tu transporte" 
                top="sky" 
                bottom="primary" 
                avatar="happy" 
                avatarColor="sunset" 
                icon={(showHeader) ? 'bars': 'arrow-left'}
                success={saved}
                goTo={() => history.goBack() } /> 
        : null }
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="medium" />
                <Heading size="3" color="base">
                    Elige tus medios de transporte habituales, en base a ellos filtraré todo lo que te pueda afectar.
                </Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6} flex={true}>
                <Switch key="tr-bus" label="Guagua" color="secondary" notify={setBus} active={transports.bus} />
                <Switch key="tr-car" label="Coche" color="secondary" notify={setCar} active={transports.car} />
                <Switch key="tr-motorcycle" label="Moto" color="secondary" notify={setMotorcycle} active={transports.motorcycle} />
                <Switch key="tr-walking" label="Caminando" color="secondary" notify={setWalking} active={transports.walking} />
                <Switch key="tr-bike" label="Bicicleta" color="secondary" notify={setBike} active={transports.bike} />
                <Switch key="tr-scooter" label="Patinete" color="secondary" notify={setScooter} active={transports.scooter} />
                <Switch key="tr-taxi" label="Taxi" color="secondary" notify={setTaxi} active={transports.taxi} />
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={saveTransport} color="secondary" loading={(saved) ? 'success' : 'idle'}>{ctaCopy}</Button>
                    <Divider size="small" />
                    <Link title="¿Por qué quiero saber tu transporte?" click={() => showScreenInfo(true)} />
                </GridColumn>
            </Grid>
        </FooterSection>
        {(serviceError) && <Dialog title="Error" body="Error" type="confirmation" close={() => showServiceError(false)} />}
        {(screenInfo) && <Dialog title="Los transportes" body="En base a tu selección de transportes puedo filtrar el contenido, de tal manera que te podré avisar de aquello que afecte no sólo a tu zona sino también a tu modo de moverte." type="info" close={() => showScreenInfo(false)} />}
    </div>
}

export default Transport;