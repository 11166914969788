import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { User } from '../../../../Models/User';
import { API } from '../../../../Utils/API/API';
import { Paths } from '../../../../Utils/Helpers';

import Grid from '../../../UI/Grid/Grid';
import GridColumn from '../../../UI/Grid/Column/Column';
import HeaderFlow from '../../../UI/Header/Flow/Flow';
import FooterSection from '../../../UI/Footer/Section/Section';
import FormInput from '../../../UI/Form/Input/Input';
import Divider from '../../../UI/Divider/Divider';
import Button from '../../../UI/Button/Button';
import Link from '../../../UI/Link/Link';
import Dialog from '../../../Dialog/Dialog';
import Loader from '../../../Loader/Loader';
import Heading from '../../../UI/Heading/Heading';
import Feedback from '../../../Feedback/Feedback';

interface UrlProps {
    ttoken: string
    utoken: string
}

const Recover = () => {

    const history = useHistory();
    
    const { utoken, ttoken } = useParams<UrlProps>();

    const [user, setUser] = useState<User>({password: ''} as User);
    const [loading, setLoading] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [infoIsValid, setInfoAsValid] = useState<boolean>(true);
    const [retypedPassword, setRetypedPassword] = useState<string>('');

    const setNewPassword = (password:string) => setUser({...user, password});
    const setPasswordVerification = (password:string) => setRetypedPassword(password);

    const resetPassword = async () => {
        try {
            await API.user.resetPassword(utoken+'/'+ttoken,user);
            setLoading(true);
        }
        catch(error) {
            setLoading(false);
            setServiceError(true);
        }
    }

    const validateReset = (submit:boolean) => {

        let isValid = (user.password.length > 0 && user.password === retypedPassword)

        // update
        setInfoAsValid(isValid);
        // submit info
        if(isValid && submit) {
            setServiceError(false);
            resetPassword();
        }
    }

    useEffect(() => {
        if(user.password !== '') validateReset(false);
    },[retypedPassword])

    return <div className="Recover">
        <HeaderFlow 
            title="Nueva contraseña" 
            top="sunset" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="canary"
            goTo={() => history.push(Paths.signIn)} />
        {(serviceError) ? <Fragment>
            <Feedback color="error">
                Parece que este link ya lo usaste y guardaste tu contraseña. <a href={Paths.signIn}><u>Puedes acceder aquí a tu cuenta...</u></a>
            </Feedback>
            <Divider size="small" />
        </Fragment> :
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="medium" />
                <Heading size="3" color="base">
                    Introduce tu nueva contraseña, recuerda que tiene que tener al menos 6 caracteres...
                </Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>}
        <Grid>
            <GridColumn size={6}>
                <FormInput 
                    type="password"
                    label="Contraseña"
                    required={true}
                    sublabel="Para acceder a tu cuenta" 
                    placeholder=""
                    valid={infoIsValid}
                    notifyValidation={setNewPassword}
                />
                <FormInput 
                    type="password"
                    label="Repetir contraseña"
                    required={true}
                    sublabel="Para evitar errores"
                    placeholder=""
                    valid={infoIsValid}
                    formatter="retype"
                    notifyValidation={setPasswordVerification}
                    referenceValue={user.password}
                    disabled={(user.password === '')}
                />
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={() => validateReset(true)} color="secondary">Guardar</Button>
                    <Divider size="small" />
                    <Link title="Si ya tienes cuenta, puedes acceder aquí" click={() => history.push(Paths.signIn)}/>
                </GridColumn>
            </Grid>
        </FooterSection>
        {(loading) && <Loader 
            body="Estoy guardando tu nueva contraseña, una vez guardada abriré la página de acceso para que entres a tu cuenta..." 
            destination={Paths.signIn}
            timeToDestination={3000}
            animation={false} />}
    </div>;
}

export default Recover;