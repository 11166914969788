import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { User, UserInfo } from '../../../Models/User';
import { API } from '../../../Utils/API/API';

import UserContext from '../../../Contexts/User';
import Feedback from '../../Feedback/Feedback';
import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import HeaderFlow from '../../UI/Header/Flow/Flow';
import HeaderSection from '../../UI/Header/Section/Section';
import FooterSection from '../../UI/Footer/Section/Section';
import FormInput from '../../UI/Form/Input/Input';
import Heading from '../../UI/Heading/Heading';
import Button from '../../UI/Button/Button';
import Divider from '../../UI/Divider/Divider';
import Dialog from '../../Dialog/Dialog';

const Profile = () => {

    const history = useHistory();
    const { profile, updateProfile } = useContext(UserContext);

    const [serviceError, setServiceError] = useState<boolean>(false);
    const [newProfile, setNewProfile] = useState<User>({...profile} as User);
    const [saved, setSaved] = useState<boolean>(false);
    const [infoIsValid, setInfoAsValid] = useState<boolean>(true);
    
    const saveProfile = async () => {

        const profileUserData = {...newProfile}

        profileUserData.data.phone_number = profileUserData.data.phoneNumber;

        try {
            
            const updatedProfile = {
                firstName: profileUserData.name,
                data: {...profileUserData.data}
            }
            
            const profile = await API.user.updateProfile(updatedProfile);
            
            updateProfile({...profile});
            setSaved(true);
            document.onclick = () => setSaved(false);
        }
        catch(error) {
            setServiceError(true);
        }
    }

    const updateName = (name:string) => setNewProfile({...newProfile, name});
    const updatePhone = (phone:number) => {

        const updatedProfile = {...newProfile};

        updatedProfile.data.phoneNumber = phone;
        setNewProfile(newProfile);
    }

    const validateProfile = () => {
        
        let isValid = (newProfile.name.length > 0);

        // update
        setInfoAsValid(isValid);
        // submit info
        if(isValid) saveProfile();
    }

    return <div className="Profile">
        <HeaderFlow 
            title="Tu perfil" 
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="grass" 
            success={saved}
            icon="bars"
            goTo={() => history.goBack()} />
        {/* user feedback: error */}
        {/* {(serviceError) && <Feedback color="error">
            Parece que hubo un error guardando tus datos, inténtalo un poco más tarde...
        </Feedback>} */}
        {/* end user feedback */}
        <Divider size="medium" />
        <Grid>
            <GridColumn size={6}>
                <FormInput 
                    type="text"
                    label="Nombre"
                    required={true}
                    sublabel="Para tratarnos de tú" 
                    placeholder=''
                    value={profile.name || ''}
                    valid={infoIsValid}
                    notifyValidation={updateName}
                />
                <FormInput 
                    type="email"
                    label="Email"
                    required={true}
                    sublabel="Para comunicarme contigo" 
                    placeholder=''
                    value={profile.email || ''}
                    disabled={true}
                />
                <FormInput 
                    type="tel"
                    label="Teléfono móvil"
                    placeholder="Ej. 612345678"
                    required={false}
                    sublabel="Opcional si no quieres usar WhatsApp, SMS o llamada" 
                    info={false}
                    disabled={false}
                    value={profile.data.phoneNumber || ''}
                    notifyValidation={updatePhone}
                />
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={validateProfile} color="secondary" loading={(saved) ? 'success' : 'idle'}>Guardar cambios</Button>
                </GridColumn>
            </Grid>
        </FooterSection>
        {/* <HeaderSection title="Zona de peligro" color="warning" background="base" />
        <Grid clearSide="bottom">
            <GridColumn size={3}>
            <Divider size="small" />
                <Heading size="4" color="error">Aquí puedes dar de baja tu cuenta. Recuerda que si ejecutas esta acción, borraré todos los datos que guardaste dentro del sistema.</Heading>
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={saveProfile} color="error" elevation={true}>Dar de baja ahora</Button>
                </GridColumn>
            </Grid>
        </FooterSection> */}
        {(serviceError) ?? <Dialog title="Error" body="Error" type="confirmation" close={() => setServiceError(false)} />}

    </div>
}

export default Profile;