import React from 'react';
import { useHistory } from 'react-router-dom';

import HeaderFlow from '../../Components/UI/Header/Flow/Flow';
import Grid from '../../Components/UI/Grid/Grid';
import GridColumn from '../../Components/UI/Grid/Column/Column';
import Divider from '../../Components/UI/Divider/Divider';
import Heading from '../../Components/UI/Heading/Heading';
import Text from '../../Components/UI/Text/Text';
import Icon from '../../Components/UI/Icon/Icon';
import Tag from '../../Components/UI/Tag/Tag';

const Inbox = () => {

    const history = useHistory();
    
    return <div className="Inbox">      
        <HeaderFlow 
            title="Notificaciones" 
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="ocean" 
            icon='arrow-left'
            goTo={() => history.goBack()} />  
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="medium" />
                <Heading size="2" color="base">
                    No tienes notificaciones pendientes.
                </Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6}>
                <Text size="l">
                    Recuerda que te notificaré aquí también todo aquello que te pueda afectar, de esta manera lo podrás consultar en cualquier momento.
                </Text>
            </GridColumn>
        </Grid>
    </div>
}

export default Inbox;