import React, { useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { API } from '../../../../Utils/API/API';
import { Paths } from '../../../../Utils/Helpers';

import UserContext from '../../../../Contexts/User';
import Grid from '../../../UI/Grid/Grid';
import GridColumn from '../../../UI/Grid/Column/Column';
import Icon from '../../../UI/Icon/Icon';
import Divider from '../../../UI/Divider/Divider';
import Heading from '../../../UI/Heading/Heading';
import HeaderFlow from '../../../UI/Header/Flow/Flow';
import Text from '../../../UI/Text/Text';
import FooterSection from '../../../UI/Footer/Section/Section';

import style from './Navigation.module.scss';

const Navigation = () => {
    
    const history = useHistory();
    const { signOut, profile } = useContext(UserContext);
    
    const deleteSession = async () => {
        try {
            await API.user.signOut();
            // delete context
            signOut(); 
            // move to next if user is going through onboarding
            history.push(Paths.home);
        }
        catch(error) {
        }
    }

    return <div className={style.Navigation}>
        <HeaderFlow 
            title="Tu área personal" 
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="sunrise" 
            icon='times'
            goTo={() => history.push(Paths.home)} />
        <Grid>
            <GridColumn size={6}>
                <Divider size="medium" />
                {(profile.name) ? <Heading size="2">Hola {profile.name}!</Heading> : <Heading size="2">Hola!</Heading>}
                <Divider size="small" /> 
                <Text size="l">Aquí puedes gestionar todos los datos de tu perfil. Recuerda que si necesitas ayuda tienes disponible la pestaña de soporte.</Text>
            </GridColumn>
        </Grid>
        <Grid clearSide="top">
            <GridColumn size={6}>
                <nav>
                    <Link to={Paths.home}>
                        <Icon name="map" type="solid" />
                        <Heading size="4">Tu mapa</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <Link to={Paths.profile.info}>
                        <Icon name="user-circle" type="solid" />
                        <Heading size="4">Perfil</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <Link to={Paths.profile.channels}>
                        <Icon name="comment-alt" type="solid" />
                        <Heading size="4">Canales</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <Link to={Paths.profile.transports}>
                        <Icon name="car-bus" type="solid" />
                        <Heading size="4">Transporte</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <Link to={Paths.profile.itinerary}>
                        <Icon name="route" type="solid" />
                        <Heading size="4">Itinerario</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <Link to={Paths.profile.filters}>
                        <Icon name="filter" type="solid" />
                        <Heading size="4">Filtros</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <Link to={Paths.inbox}>
                        <Icon name="bell" type="solid" />
                        <Heading size="4">Notificaciones</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <Link to={Paths.profile.privacy}>
                        <Icon name="lock" type="solid" />
                        <Heading size="4">Privacidad</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </Link>
                    <a href="https://lpgcmovilidad.com/contacto" target="_blank">
                        <Icon name="user-headset" type="solid" />
                        <Heading size="4">Soporte</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </a>
                    <a onClick={deleteSession}>
                        <Icon name="sign-out-alt" type="solid" />
                        <Heading size="4">Salir</Heading>
                        <Icon name="arrow-right" type="outline" />
                    </a>
                </nav>
            </GridColumn>
        </Grid>
        <FooterSection></FooterSection>
    </div>
}

export default Navigation;