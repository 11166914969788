import React, { Fragment } from 'react';

import { BusLineColors, ContentColors } from '../../../Utils/Helpers';
import { BusLineIncidenceContent, NologisContent } from '../../../Models/Content';

import Heading from '../../UI/Heading/Heading';
import Text from '../../UI/Text/Text';
import Divider from '../../UI/Divider/Divider';
import Tag from '../../UI/Tag/Tag';
import Icon from '../../UI/Icon/Icon';
import Calendar from '../../UI/Calendar/Calendar';

import style from '../Content.module.scss';

interface BusIncidenceProps {
    icon: string
    meta: NologisContent
    info: BusLineIncidenceContent
    expanded?: boolean
}

const BusIncidence = ({meta, info, icon, expanded = false}: BusIncidenceProps) => {

    let isActive = true;

    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Desvío de Guaguas</Heading>
            <Text size="l">{meta.title}</Text>
            {(isActive) && <Fragment>
                <Divider size="small" /> 
                <Tag color="neutral" customTextColor={meta.themeColor} size="small" animate={true}>{meta.state}</Tag>
            </Fragment>}
        </header>
        {(expanded) && <div className={style.Content__Body}>
            {/* Lines */}
            {(info.line_stops) && <Fragment>
                <Heading size="3">
                    <Icon name="bus" type="solid" /> Líneas afectadas
                </Heading>
                <Divider size="small" /> 
                <div className={style.Content__TagWrapper}>
                    {info.line_stops.map((busLine: any) => {
                        return <Tag key={'bl-'+busLine} customColor={BusLineColors(busLine)}>{busLine}</Tag>
                    })}
                </div>
            </Fragment>}
            {/* Bus Stops */}
            {(info.affection) && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3">
                    <Icon name="users-class" type="solid" /> Paradas afectadas
                </Heading>
                <Divider size="small" /> 
                <div className={style.Content__TagWrapper}>
                    {info.affection.replace(' y',',').split(',').map((busStop: any)=> {
                        return <Tag key={'bs-'+busStop} customColor={ContentColors('busStop')}>{busStop}</Tag>;
                    })}
                </div>
            </Fragment>}
            {/* Timeframe */}
            {(info.start_date) && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3">
                    <Icon name="clock" type="solid" />
                    {(info.end_date) ? ' Duración estimada' : ' Inicio del desvío'}
                </Heading>
                <Divider size="small" /> 
                <Calendar from={info.start_date} to={info.end_date} />
            </Fragment>}
            {/* Alternative */}
            {info.description && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3" color="base">
                    <Icon name="map-signs" type="solid" /> Información adicional
                </Heading>
                <Divider size="x-small" /> 
                <Text size="l">{info.description}</Text>
            </Fragment>}
        </div>}
    </Fragment>;
}

export default BusIncidence;
