import React from 'react';
import style from './Divider.module.scss';

interface props {
    size?: 'large' | 'medium' | 'normal' | 'small' | 'x-small';
    type?: 'horizontal' | 'vertical';
}

const Divider: React.FC<props> = props => {

    const dividerConfig = (!props.size) ? style.Divider : [style.Divider,style['Divider--'+props.size]].join(' ');

    return <div className={dividerConfig}>
        {props.children}
    </div>;
}

export default Divider;