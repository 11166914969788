import React, { Fragment } from 'react';

import { NologisContent, RenovationContent } from '../../../Models/Content';

import Heading from '../../UI/Heading/Heading';
import Divider from '../../UI/Divider/Divider';
import Tag from '../../UI/Tag/Tag';
import Icon from '../../UI/Icon/Icon';
import Calendar from '../../UI/Calendar/Calendar';
import Text from '../../UI/Text/Text';

import style from '../Content.module.scss';

interface RenovationProps {
    icon: string
    meta: NologisContent
    info: RenovationContent
    expanded?: boolean
}

const Renovation = ({meta, info, icon, expanded = false}: RenovationProps) => {
    
    let isActive = true;

    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Obras</Heading>
            <Text size="l">{meta.title}</Text>
            {(isActive) && <Fragment>
                <Divider size="small" /> 
                <Tag color="neutral" customTextColor={meta.themeColor} size="small" animate={true}>{meta.state}</Tag>  
            </Fragment>}
            {/* <Tag color="neutral" customTextColor={meta.themeColor}>Obra {info?.construction_type.replace('_',' ')}</Tag>   */}
        </header>
        {(expanded) && <div className={style.Content__Body}>
            {/* Timeframe */}
            {info.origin_date_and_time && <Fragment>
                <Heading size="3" color="base">
                    <Icon name="clock" type="solid" /> Duración estimada
                </Heading>
                <Divider size="small" /> 
                <Calendar from={info.origin_date_and_time} to={info.end_date_and_time} />
            </Fragment>}
            {/* Itinerary */}
            {/* Alternative */}
            {info.description && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3" color="base">
                    <Icon name="map-signs" type="solid" /> Información adicional
                </Heading>
                <Divider size="x-small" /> 
                <Text size="l">{info.description}</Text>
            </Fragment>}
        </div>}
    </Fragment>;
}

export default Renovation;
