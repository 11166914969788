import React from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '../UI/Grid/Grid';
import GridColumn from '../UI/Grid/Column/Column';
import Divider from '../UI/Divider/Divider';
import Avatar from '../UI/Avatar/Avatar';
import Landscape from '../UI/Landscape/Landscape';
import Button from '../UI/Button/Button';
import Heading from '../UI/Heading/Heading';

import style from './Loader.module.scss';

interface LoaderProps {
    title?: string
    body?: string
    buttonTitle?: string
    buttonAction?: () => void
    destination?: string
    timeToDestination?: number
    animation?: boolean
}

const Loader = ({title, body, buttonTitle, buttonAction, destination, timeToDestination, animation = true}:LoaderProps) => {
    
    const history = useHistory();   

    if(destination !== undefined && timeToDestination) {
        setTimeout(() => {
            history.push(destination || '/');
        },timeToDestination);
    }

    return <div className={style.Loader}>
        <Grid>
            <GridColumn size={6}>
                <Avatar size="large" color="sunrise" loading={false} elevation={false} />
                <Divider size="medium" />
                <Heading size="3"> {(title) ? title : 'Un momento por favor'}</Heading>
                <p>{(body) ? body : "Estoy cargando el contenido que necesitas, dame unos segundos..."}</p>
                <Divider size="medium" />
                {(buttonTitle && buttonAction) ? 
                    <Button click={buttonAction} color="base">{buttonTitle}</Button>
                : null }
            </GridColumn>
        </Grid>
        <Landscape type="city" clouds={true} />
    </div> 
}

export default Loader;