import React, { Fragment } from 'react';

import { CardColorTokens, ButtonColorTokens } from '../../../Models/Theme';

import Button from '../Button/Button';
import Divider from '../Divider/Divider';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import Heading from '../../UI/Heading/Heading';
import Text from '../../UI/Text/Text';

import style from './Card.module.scss';

interface CardProps {
    title: string
    type: CardColorTokens
    clearPadding?: boolean
    body?: string
    icon?: string
    buttonColor?: ButtonColorTokens
    multiAction?: boolean
    closeAction?: () => void
    primaryButtonLabel?: string
    primaryButtonAction?: () => void
    secondaryButtonLabel?: string
    secondaryButtonAction?: () => void
}

const Card: React.FC<CardProps> = ({title = '',type = 'info', clearPadding = false, body = null, icon = '', buttonColor = 'base', primaryButtonLabel = null, closeAction, primaryButtonAction, multiAction = false, secondaryButtonLabel, secondaryButtonAction, children}) => {

    const isMultiAction = (!multiAction) ? style.Card__action_wrapper : style.Card__action_wrapper__column;

    return <div className={style.Card}>
        <header className={style['Card__header__'+type]}>
            <Heading size="3">
                {(icon !== '') && <span><Icon name={icon} /></span>} 
                {title}
            </Heading>
            {(closeAction) && <Button type="minimal" click={closeAction} icon="times" />}
        </header>
        <div className={style.Card__body}>
            {(!clearPadding) && <Divider size="small" />}
            {(body !== '') && <Text size="l">{body}</Text>}
            {(!clearPadding) && <Divider size="small" />}
            <div className={isMultiAction}>
                {children}
            </div>
            {(!clearPadding) && <Divider size="small" />}
            {(primaryButtonLabel && primaryButtonAction) && <Fragment>
                <Divider size="small" />
                <div className={style.Card__action_wrapper}>
                    <Button click={primaryButtonAction} color={buttonColor}>{primaryButtonLabel}</Button> 
                </div>
            </Fragment>}
            {(secondaryButtonLabel && secondaryButtonAction) && <Fragment>
                <Divider size="x-small" />
                <Link title={secondaryButtonLabel} click={secondaryButtonAction} />
                <Divider size="x-small" />
            </Fragment>}
        </div>
    </div>
}

export default Card;