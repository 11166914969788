import React from 'react';
import { useHistory } from 'react-router-dom';

import { Paths } from '../../Utils/Helpers';

import Loader from '../../Components/Loader/Loader';

const Error = () => {
    
    const history = useHistory();   

    return <div className="Error">
        <Loader 
            title="404" 
            body="Lo siento, no pude encontrar la página que estás buscando" 
            buttonTitle="Volver al inicio" 
            buttonAction={() => history.push(Paths.home)} 
            animation={false} />
    </div>;
}

export default Error;