import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from '../../../../Models/User';
import { API } from '../../../../Utils/API/API';
import { Paths } from '../../../../Utils/Helpers';

import UserContext from '../../../../Contexts/User';
import Feedback from '../../../Feedback/Feedback';
import Grid from '../../../UI/Grid/Grid';
import GridColumn from '../../../UI/Grid/Column/Column';
import HeaderFlow from '../../../UI/Header/Flow/Flow';
import FooterSection from '../../../UI/Footer/Section/Section';
import FormInput from '../../../UI/Form/Input/Input';
import Button from '../../../UI/Button/Button';
import Divider from '../../../UI/Divider/Divider';
import Link from '../../../UI/Link/Link';
import Loader from '../../../Loader/Loader';
import Heading from '../../../UI/Heading/Heading';

const SignUp = () => {

    const history = useHistory();
    const { signIn } = useContext(UserContext);
    
    const [newUser, setNewUser] = useState<User>({email: '', password: '', terms: false, clientAlias: 'lpgc-movilidad'} as User);
    const [loading, setLoading] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [infoIsValid, setInfoAsValid] = useState<boolean>(true);
    const [retypedPassword, setRetypedPassword] = useState<string>('');

    const setEmail = (email:string) => setNewUser({...newUser, email});
    const setPassword = (password:string) => setNewUser({...newUser, password});
    const setTerms = (terms:boolean) => setNewUser({...newUser, terms});
    const setPasswordVerification = (password:string) => setRetypedPassword(password);

    const createAccount = async () => {
        try {
            const session = await API.user.signUp(newUser);
            signIn(session.authToken);
            history.push(Paths.onboarding.channels);
            // setLoading(true);
        }
        catch(error) {
            setTimeout(() => {
                setLoading(false);
            },500);
            setServiceError(true);
        }
    }

    const validateSignUp = () => {
        
        let isValid = (newUser.email.length > 0 && newUser.password.length > 0 && newUser.terms && newUser.password === retypedPassword);
        
        // update
        setInfoAsValid(isValid);
        // submit info
        if(isValid) {
            createAccount();
        }
    } 

    return <div className="Register">
        <HeaderFlow 
            title="Crea tu cuenta" 
            subtitle="Hola!"
            steps="Soy Ale, tu asistente de movilidad"
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            goTo={() => history.push(Paths.home) } />
        {/* user feedback: error */}
        {(serviceError) && <Feedback color="warning">
            <Divider size="x-small" />
            <Heading size="3">
                Parece que tu correo ya existe en la base de datos, recuerda que <a href={Paths.signIn}><u>siempre puedes acceder aquí...</u></a>
            </Heading>
        </Feedback>}
        {/* end user feedback */}
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size={(serviceError) ? "x-small" : "medium"} />
                <Heading size="3" color="base">
                    Simplemente rellena los datos, completa tu perfil y a partir de ahí, me encargaré de avisarte, ¿vamos?.
                </Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6}>
                <FormInput 
                    type="email"
                    label="Email"
                    required={true}
                    sublabel="Para comunicarme contigo" 
                    placeholder=""
                    valid={infoIsValid}
                    notifyValidation={setEmail}
                />
                <FormInput 
                    type="password"
                    label="Contraseña"
                    required={true}
                    sublabel="Para acceder a tu cuenta" 
                    placeholder=""
                    valid={infoIsValid}
                    notifyValidation={setPassword}
                />
                <FormInput 
                    type="password"
                    label="Repetir contraseña"
                    required={true}
                    sublabel="Para evitar errores"
                    placeholder=""
                    valid={infoIsValid}
                    formatter="retype"
                    notifyValidation={setPasswordVerification}
                    referenceValue={newUser.password}
                    disabled={(newUser.password === '')}
                />
                <FormInput 
                    type="checkbox"
                    label="Términos y condiciones"
                    required={true}
                    sublabel="Puedes leer todos los términos y condiciones "
                    sublabelLink={Paths.terms}
                    valid={infoIsValid}
                    placeholder="Acepto los términos y condiciones"
                    notifyValidation={setTerms}
                />
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={validateSignUp} color="secondary">Crear</Button>
                    <Divider size="small" />
                    <Link title="Si ya tienes cuenta, puedes acceder aquí" click={() => history.push(Paths.signIn)}/>
                </GridColumn>
            </Grid>
        </FooterSection>
        {(loading) ? <Loader body="Estoy guardando tus datos antes de continuar..." destination={Paths.onboarding.channels} timeToDestination={3000} animation={false} /> : null}
    </div>
}

export default SignUp;