import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Paths } from '../../Utils/Helpers';

import UserProfile from '../../Components/User/Profile/Profile';
import UserFilters from '../../Components/User/Filters/Filters';
import UserTransports from '../../Components/User/Transport/Transport';
import UserItinerary from '../../Components/User/Itinerary/Itinerary';
import UserChannels from '../../Components/User/Channels/Channels';
import UserPrivacy from '../../Components/User/Privacy/Privacy';
import UserNavigation from '../../Components/User/Account/Navigation/Navigation';

const User = () => {
    return <div className="User">
        <Switch>
            <Route path={Paths.profile.info}>
                <UserProfile />
            </Route>
            <Route path={Paths.profile.channels}>
                <UserChannels />
            </Route>
            <Route path={Paths.profile.filters}>
                <UserFilters />
            </Route>
            <Route path={Paths.profile.transports}>
                <UserTransports />
            </Route>
            <Route path={Paths.profile.itinerary}>
                <UserItinerary />
            </Route>
            <Route path={Paths.profile.privacy}>
                <UserPrivacy />
            </Route>
            <Route path={Paths.profile.account}>
                <UserNavigation />
            </Route>
        </Switch>
    </div>;
}

export default User;