import { Config } from '../Config';
import { User } from '../../Models/User';
import { get, post, put } from './Helpers';

export const API = {
    user: {
        signIn: (userData:User) => post(`${Config.foundationBasePath}/v5/crm/auth/login`, {...userData}),
        signUp: (userData:User) => post(`${Config.foundationBasePath}/v5/crm/auth/register`,{...userData}),
        recoverPassword: (userData:User) => post(`${Config.foundationBasePath}/v5/crm/auth/recovery`,{...userData}),
        resetPassword: (tokenParams:string,userData:User) => post(`${Config.foundationBasePath}/v5/crm/auth/reset/${tokenParams}`,{...userData}),
        profile: () => get(Config.foundationBasePath+'/v5/crm/auth/profile'),
        updateProfile: (newProfileData:any) => put(`${Config.foundationBasePath}/v5/crm/auth/profile`,{...newProfileData}),
        signOut: () => post(`${Config.foundationBasePath}/v5/crm/logout`,{}),
        deleteProfile: () => post(`${Config.foundationBasePath}/v5/crm/auth/deregister`,{})
    },
    content: {
        models: () => get(`${Config.foundationBasePath}/v5/cms/api/models`),
        feedByModel: (modelId:string) => get(`${Config.foundationBasePath}/v5/cms/api/content/${modelId}`),
        detail: (modelId:string, id:string) => get(`${Config.foundationBasePath}/v5/cms/api/content/${modelId}/${id}`),
        contentFields: (modelName: string) => get(`${Config.foundationBasePath}/v5/cms/api/fields/${modelName}`)
    }
};
 