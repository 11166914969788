import React, { useState, useEffect, Fragment } from 'react';
import { ColorTokens } from '../../../Models/Theme';

import Icon from '../Icon/Icon';
import style from './Switch.module.scss';

interface SwitchProps {
    label: string
    active?: boolean
    sublabel?: string
    icon?: string
    labelSize?: 'label-small' | 'label-large'
    color?: ColorTokens
    notify?: (data:any) => void
}

const CSS_PREFIX:string = 'Switch--';

const Switch = ({label, active, sublabel, icon, labelSize = 'label-large', notify, color = 'primary'}: SwitchProps) => {

    const [state, setState] = useState({
        value: false,
        style: ''
    });

    const styleConfig:string[] = [style.Switch,style[CSS_PREFIX+labelSize],style[CSS_PREFIX+color],state.style];

    const changeSwitch = (newValue:boolean) => {

        let newState = {...state};

        newState.value = newValue;
        newState.style = (newValue) ? style['Switch--on--'+color] : style['Switch--off--'+color];

        setState(newState);

        if(notify) notify(newValue);
    }

    useEffect(() => {
        changeSwitch(active);
    },[active]);

    return <div className={styleConfig.join(' ')} onClick={() => changeSwitch(!state.value)}>
        <div className={style.Switch__label}>
            {(icon) && <div className={style.Switch__label__icon}>
                <Icon name={icon || ''} />
            </div>}
            <div className={style.Switch__label__wrapper}>
                <span>{label}</span>
                {(sublabel) && <p>{sublabel}</p>}
            </div>
        </div>
        <div className={style.Switch__action}>
            <button onClick={() => {}}></button>
        </div>
    </div>
}

export default Switch;