import React, { Fragment, useState } from 'react';
import { Paths } from '../../Utils/Helpers';

import Card from '../UI/Card/Card';

const getCookie = (cname:string) => {

    const name = cname + "=";
    const ca = document.cookie.split(';');

    for(var i = 0; i < ca.length; i++) {
        
        let c = ca[i];
        
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0)
            return c.substring(name.length, c.length);
    }

    return false;
}

const Cookies = () => {

    const [cookie, setCookie] = useState<boolean>(!!getCookie('ale-lpgc-movilidad'));

    const dropCookie = () => {
        
        const date = new Date();

        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));

        const expires = "expires="+date.toUTCString();
        
        document.cookie = 'ale-lpgc-movilidad=navigation;'+expires+';path=/';

        setCookie(true);
    }

    return <Fragment>
        {(!cookie) && <Card 
            title="Cookies" 
            body="Recuerda que uso cookies propias y de terceros para mejorar la navegabilidad de esta página, su acceso y la personalización de los contenidos. Si continúas navegando, entiendo que me das tu consentimiento expreso para el uso de esta web. Puedes obtener más información y la configuración en la política de cookies."
            clearPadding={true}
            primaryButtonLabel="Aceptar"
            type="mandatory" 
            icon="cookie-bite"
            primaryButtonAction={dropCookie}
            secondaryButtonLabel="Accede aquí a la política de privacidad"
            secondaryButtonAction={() => {
                window.open(Paths.terms,'_blank');
            }}
        />}
    </Fragment>;
}

export default Cookies;