import React, { Fragment } from 'react';

import { BusLineColors } from '../../../Utils/Helpers';
import { NologisContent, BusStopContent } from '../../../Models/Content';

import Heading from '../../UI/Heading/Heading';
import Text from '../../UI/Text/Text';
import Divider from '../../UI/Divider/Divider';
import Tag from '../../UI/Tag/Tag';
import Icon from '../../UI/Icon/Icon';

import style from '../Content.module.scss';

interface BusStopProps {
    icon: string
    meta: NologisContent
    info: BusStopContent
    expanded?: boolean
}

const BusStop = ({meta, info, icon, expanded = false}: BusStopProps) => {
    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Parada de Guaguas</Heading>
            <Text size="l">{meta.title}</Text>
            <Divider size="small" /> 
            <Tag color="light" customTextColor={meta.themeColor} size="small" animate={true}>
                {info.nologis_state.replace(/-/g,' ')}
            </Tag>            
        </header>
        <div className={style.Content__Body}>
            {(info.line_stops) && <Fragment>
                <Heading size="3">
                    <Icon name="route" type="solid" /> Líneas
                </Heading>
                <Divider size="small" /> 
                <div className={style.Content__TagWrapper}>
                    {info.line_stops.map((busLine: any) => {
                        return <Tag key={'bl-'+busLine} customColor={BusLineColors(busLine)}>{busLine}</Tag>
                    })}
                </div>
                <Divider size="medium" /> 
            </Fragment>}
            {/* Note */}
            {info.description && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3" color="base">
                    <Icon name="map-signs" type="solid" /> Información adicional
                </Heading>
                <Divider size="x-small" /> 
                <Text size="l">{info.description}</Text>
            </Fragment>}
        </div>
    </Fragment>;
}

export default BusStop;