import React, { Fragment } from 'react';

import { NologisContent, BusLineContent } from '../../../Models/Content';

import Heading from '../../UI/Heading/Heading';
import Divider from '../../UI/Divider/Divider';
import Tag from '../../UI/Tag/Tag';
import Icon from '../../UI/Icon/Icon';

import style from '../Content.module.scss';

interface BusLineProps {
    icon: string
    meta: NologisContent
    info: BusLineContent
    expanded?: boolean
}

const BusLine = ({meta, info, icon, expanded = false}: BusLineProps) => {
    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Línea de Guaguas</Heading>
            <p>{meta.title}</p>
            <Divider size="small" /> 
            <Tag color="light" customTextColor={meta.themeColor}>{info.nologis_state || 'Activa'}</Tag>
        </header>
        <div className={style.Content__Body}>           
            {/* Note */}
            {info.description && <Fragment>
                <Divider size="normal" /> 
                <Heading size="6" color="base"><Icon name="map-signs" type="solid" /> Comentarios</Heading>
                <Divider size="x-small" /> 
                <p>{info.description}</p>
            </Fragment>}
        </div>
    </Fragment>;
}

export default BusLine;