import React, { Fragment, useState, useEffect } from 'react';

import { NologisContent, EventContent } from '../../../Models/Content';
import { ContentColors, BusLineColors } from '../../../Utils/Helpers';
import { API } from '../../../Utils/API/API';

import Heading from '../../UI/Heading/Heading';
import Text from '../../UI/Text/Text';
import Divider from '../../UI/Divider/Divider';
import Tag from '../../UI/Tag/Tag';
import Icon from '../../UI/Icon/Icon';
import Calendar from '../../UI/Calendar/Calendar';

import style from '../Content.module.scss';

interface EventProps {
    icon: string
    meta: NologisContent
    info: EventContent
    expanded?: boolean
}

const Event = ({meta, info, icon, expanded = false}: EventProps) => {
    
    let isActive = true;
    const [activeLines, setActiveLines] = useState<string[]>([]);

    const getFields = async () => {
        try {
            const fields = await API.content.contentFields('event');
            const busLines = fields.filter((field: any) => field.id === 3763)[0];
            let lineNames: string[] = [];

            for (const key in busLines.choices_json) {
                if(info.line_stops.indexOf(key) !== -1) {
                    lineNames.push(busLines.choices_json[key]);
                }
            }

            setActiveLines([...lineNames]);
        }
        catch(error) {
            // active lines wouldn't be rendered by default
        }
    }

    useEffect(() => {
        getFields();
    }, [])

    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Evento</Heading>
            <Text size="l">{meta.title}</Text>
            {(isActive) && <Fragment>
                <Divider size="small" /> 
                <Tag color="neutral" customTextColor={meta.themeColor} size="small" animate={true}>{meta.state}</Tag>
            </Fragment>}
        </header>
        {(expanded) && <div className={style.Content__Body}>
            {/* Timeframe */}
            {(info.creation_date_and_time) && <Fragment>
                <Heading size="3" color="base">
                    <Icon name="clock" type="solid" /> 
                    {(info.end_date_and_time) ? ' Duración estimada' : ' Inicio del evento'}
                </Heading>
                <Divider size="small" /> 
                <Calendar from={info.creation_date_and_time} to={info.end_date_and_time} />
            </Fragment>}
            {/* Active Bus Stops */}
            {(activeLines.length > 0) && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3">
                    <Icon name="bus" type="solid" /> Líneas para llegar al evento
                </Heading>
                <Divider size="small" /> 
                <div className={style.Content__TagWrapper}>
                    {activeLines.map((busStop: any) => {
                        return <Tag key={'bs-'+busStop} customColor={BusLineColors(busStop)} size="small">{busStop}</Tag>;
                    })}
                </div>
            </Fragment>}
            {/* Itinerary */}
            {/* Alternative */}
            {info.description && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3" color="base">
                    <Icon name="map-signs" type="solid" /> Información adicional
                </Heading>
                <Divider size="x-small" /> 
                <Text size="l">{info.description}</Text>
            </Fragment>}
        </div>}
    </Fragment>;
}

export default Event;