import React, { useContext, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Paths } from '../../../Utils/Helpers';

import UserContext from '../../../Contexts/User';

import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import Button from '../../UI/Button/Button';
import Icon from '../../UI/Icon/Icon';

import style from './Toolbar.module.scss';
import Column from '../../UI/Grid/Column/Column';

const Toolbar = () => {

    const { isSignedIn } = useContext(UserContext);
    const [session, setSession] = useState<boolean>(false);

    useEffect(() => {
        setSession(isSignedIn);
    },[isSignedIn]);

    return <div className={style.Toolbar}>
        <Grid>
            <GridColumn size={1} clear={true}>
                {/* <Link to={Paths.profile.filters}>
                    <Button type="rounded" color="base" click={() => {}}>
                        <Icon name="filter" />
                    </Button>
                </Link>   */}
                <Link to={Paths.inbox}>
                    <Button type="rounded" color="base" click={() => {}}>
                        <Icon name="inbox" />
                    </Button>
                </Link>
                <Link to={Paths.profile.account}>
                    <Button type="rounded" color="base" click={() => {}}>
                        {/* {(session) ? <Icon name='user'/> : <Icon name='sign-in'/>} */}
                        <Icon name='user'/>
                    </Button>
                </Link>
            </GridColumn>
            <GridColumn size={5} clear={true}></GridColumn>
        </Grid>
    </div>
}

export default Toolbar;