import React from 'react';
import style from './Link.module.scss';

interface LinkProps {
    title: string
    click: () => void
}

const Link = ({title, click}: LinkProps) => {
    return <button className={style.Link} onClick={click}>
        <u>{title}</u>
    </button>;
}

export default Link;