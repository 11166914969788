const KEY_NAME: string = 'aleToken';

export const GetTokenValue = () => {
    const tokenValue = localStorage.getItem(KEY_NAME);
    return (tokenValue) ? {'x-nologis-crm-token': tokenValue} : undefined;
};

export const SaveToken = (authToken: string) => {
    localStorage.setItem(KEY_NAME,authToken);
}

export const DeleteToken = () => {
    localStorage.removeItem(KEY_NAME);
}