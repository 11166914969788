import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from '../../Utils/Helpers';

import UserSignIn from '../../Components/User/Account/SignIn/SignIn';
import UserRecover from '../../Components/User/Account/Recover/Recover';
import UserNewPassword from '../../Components/User/Account/NewPassword/NewPassword';
import UserSignUp from '../../Components/User/Account/SignUp/SignUp';

const Access = () => {
    return <div className="Access">
        <Switch>
            <Route path={Paths.recoverPassword}>
                <UserRecover />
            </Route>
            <Route path={Paths.newPassword}>
                <UserNewPassword />
            </Route>
            <Route path={Paths.signIn}>
                <UserSignIn />
            </Route>
            <Route path={Paths.signUp}>
                <UserSignUp />
            </Route>
        </Switch>
    </div>;
}

export default Access;