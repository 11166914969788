import React from 'react';
import style from './Grid.module.scss';

interface props {
    clearSide?: 'top' | 'bottom' | 'top-bottom';
    toolbar?: boolean;
    row?: boolean
}

const Grid: React.FC<props> = props => {

    const CSS_PREFIX = 'Grid--';
    const gridConfig = [style.Grid];

    for (let key in props) {
        switch(key) {
            case 'clearSide':
                gridConfig.push(style[CSS_PREFIX+'clear-'+props[key]]);
                break;
            case 'toolbar':
                if(props[key]) gridConfig.push(style[CSS_PREFIX+key]);
                break;
            case 'row':
                if(props[key]) gridConfig.push(style[CSS_PREFIX+key]);
                break;
        }
    }

    return <div className={gridConfig.join(' ')}>
        {props.children}
    </div>;
}

export default Grid;