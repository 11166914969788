import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from '../../../../Models/User';
import { API } from '../../../../Utils/API/API';
import { Paths } from '../../../../Utils/Helpers';

import UserContext from '../../../../Contexts/User';
import Feedback from '../../../Feedback/Feedback';
import Grid from '../../../UI/Grid/Grid';
import GridColumn from '../../../UI/Grid/Column/Column';
import HeaderFlow from '../../../UI/Header/Flow/Flow';
import FooterSection from '../../../UI/Footer/Section/Section';
import FormInput from '../../../UI/Form/Input/Input';
import Divider from '../../../UI/Divider/Divider';
import Button from '../../../UI/Button/Button';
import Loader from '../../../Loader/Loader';
import Heading from '../../../UI/Heading/Heading';
import Text from '../../../UI/Text/Text';

const SignIn = () => {

    const history = useHistory();
    const userContext = useContext(UserContext);
    
    const [user, setUser] = useState<User>({email: '', password: ''} as User);
    const [loading, setLoading] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [infoIsValid, setInfoAsValid] = useState<boolean>(true);

    const setEmail = (email:string) => setUser({...user, email});
    const setPassword = (password:string) => setUser({...user, password});

    const signIn = async () => {
        try {
            const session = await API.user.signIn(user);
            userContext.signIn(session.authToken);
            history.push(Paths.profile.account);
        }
        catch(error) {
            setTimeout(() => {
                setLoading(false);
            },500);
            setServiceError(true);
        }
    }

    const validateSignIn = () => {
        
        let isValid = true;

        // check form
        for (const key in user) { 
            if(user[key].length === 0 || !user[key]) isValid = false;
        }
        // update
        setInfoAsValid(isValid);
        // submit info
        if(isValid) {
            setLoading(true);
            signIn();
        }
    } 

    return <div className="Access--sign-in">
        <HeaderFlow 
            title="Entra en tu cuenta" 
            subtitle="Acceso"
            steps="Ale, tu asistente de movilidad"
            top="sunset" 
            bottom="primary" 
            avatar={(!serviceError) ? 'happy' : 'sad'} 
            avatarColor="sunset"
            success={false}
            goTo={() => history.push(Paths.home) } />
        {/* user feedback: error */}
        {(serviceError) && <Feedback color="error">
            <Divider size="x-small" />
            <Heading size="3">
                Parece que tus credenciales no son correctas, recuerda que siempre puedes recuperar <a href={Paths.recoverPassword}><u>tu contraseña aquí...</u></a>
            </Heading>
        </Feedback>}
        {/* end user feedback */}
        <Grid>
            <GridColumn size={6}>
                <Divider size={(serviceError) ? "x-small" : "medium"} />
                <FormInput 
                    type="email"
                    label="Email"
                    required={true}
                    sublabel="Que usaste para crear tu cuenta" 
                    placeholder=""
                    valid={infoIsValid}
                    notifyValidation={setEmail}
                />
                <FormInput 
                    type="password"
                    label="Contraseña"
                    required={true}
                    sublabel="¿La olvidaste? Recupérala " 
                    placeholder=""
                    notifyValidation={setPassword}
                    valid={infoIsValid}
                    sublabelLink="/acceso/recuperar"
                />
                <Divider size="small" />
                <Button click={validateSignIn} color="secondary">Entrar</Button>
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6}>
                <Divider>
                    <Text size="l">si no tienes cuenta, crea una ahora</Text>
                </Divider>
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6}>
                <Button click={() => history.push(Paths.signUp)} color="base" elevation={true}>Crear cuenta</Button>
            </GridColumn>
        </Grid>
        {/* <Grid>
            <GridColumn size={6}>
                <Divider>
                    <p>o también puedes acceder con</p>
                </Divider>
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={3}>
                <Button click={signIn} color="facebook" icon="facebook" iconType="social" elevation={true}>Facebook</Button>
            </GridColumn>
            <GridColumn size={3}>
                <Button click={signIn} color="gmail" icon="google" iconType="social" elevation={true}>Gmail</Button>
            </GridColumn>
        </Grid> */}
        <FooterSection></FooterSection>
        {(loading) && <Loader body="Estoy comprobando los datos antes de continuar..." animation={false} />}
    </div>;
}

export default SignIn;