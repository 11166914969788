import React from 'react';
import { AvatarColorTokens, ColorTokens, AvatarFaceTokens } from '../../../../Models/Theme';

import Avatar from '../../Avatar/Avatar';
import Button from '../../../UI/Button/Button';
import Icon from '../../Icon/Icon';
import Heading from '../../Heading/Heading';
import Text from '../../Text/Text';

import style from './Flow.module.scss';

interface SectionProps {
    title: string
    subtitle?: string
    steps?: string
    color?: ColorTokens
    top?: AvatarColorTokens
    bottom?: ColorTokens
    avatar?: AvatarFaceTokens
    avatarColor?: AvatarColorTokens
    success?: boolean
    icon?: string
    goTo?: () => void;
}

const Section = ({title, subtitle, steps, color, top, bottom, avatar, avatarColor, success, icon = 'arrow-left', goTo}: SectionProps) => {

    const successMessage:boolean = success;
    
    return <div className={style.Flow}>
        <div className={style.Sky}>
            {[0,1,2,3,4].map(key => {
                return <div key={'cl-'+key} className={[style.Cloud,style['Cloud--'+key]].join(' ')}></div>
            })}
        </div>
        <div className={style.Flow__actions}>
            {(goTo) && <Button click={goTo} type="minimal">
                <Icon name={icon} type="outline" />
            </Button>}
        </div>
        <div className={style.Flow__top}>
            <Heading size="3" color="neutral">{subtitle}</Heading>
            <Text size="l">{steps}</Text>
        </div>
        {(!successMessage) && <div className={style.Flow__bottom}>
            <Heading size="3" color="neutral">{title}</Heading>
        </div>}
        {(successMessage) && <div className={[style.Flow__bottom__success].join(' ')}>
            <Heading size="3" color="neutral">Cambios guardados</Heading>
        </div>}
        <Avatar size="medium" color={avatarColor} elevation={true} />
    </div>
}

export default Section;