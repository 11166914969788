import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import HeaderFlow from '../../UI/Header/Flow/Flow';
import UserChannels from '../Channels/Channels';
import UserItinerary from '../Itinerary/Itinerary';
import UserTransport from '../Transport/Transport';

interface StepsProps {
    name: 'channels' | 'transport' | 'itinerary'
}

const Steps = ({name}: StepsProps) => {

    const history = useHistory();
    let stepToRenders = undefined;

    switch(name) {
        case 'channels':
            stepToRenders = <Fragment>
                <HeaderFlow 
                    title="Comunicaciones" 
                    subtitle="Completa tu perfil"
                    steps="Primer paso de 3"
                    top="sky" 
                    bottom="primary" 
                    avatar="happy" 
                    avatarColor="sunset" 
                />
                    <UserChannels showHeader={false} ctaCopy="Guardar y continuar" />
            </Fragment>;
            break;
        case 'transport': 
            stepToRenders = <Fragment>
                <HeaderFlow 
                    title="Transporte" 
                    subtitle="Completa tu perfil"
                    steps="Segundo paso de 3"
                    top="sky" 
                    bottom="primary" 
                    avatar="happy"
                    avatarColor="grass" 
                    goTo={() => history.goBack() } />
                <UserTransport showHeader={false} ctaCopy="Guardar y continuar" />
            </Fragment>;
            break;
        case 'itinerary':
            stepToRenders = <Fragment>
                <HeaderFlow 
                    title="Itinerario" 
                    subtitle="Completa tu perfil"
                    steps="Último paso de 3"
                    top="sky" 
                    bottom="primary" 
                    avatar="happy" 
                    avatarColor="sunrise" 
                    goTo={() => history.goBack() } />
                <UserItinerary showHeader={false} ctaCopy="Finalizar" />
            </Fragment>;
            break;
    }

    return <div className="Step">
        {stepToRenders}
    </div>;
}

export default Steps;