import React, { Fragment } from 'react';

import { NologisContent, TrafficIncidenceContent } from '../../../Models/Content';

import Heading from '../../UI/Heading/Heading';
import Divider from '../../UI/Divider/Divider';
import Tag from '../../UI/Tag/Tag';
import Icon from '../../UI/Icon/Icon';

import style from '../Content.module.scss';

interface IncidenceProps {
    icon: string
    meta: NologisContent
    info: TrafficIncidenceContent
    expanded?: boolean
}

const Incidence = ({meta, info, icon, expanded = true}: IncidenceProps) => {
    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Incidencia de tráfico</Heading>
            <p>{meta.title}</p>
            <Divider size="small" /> 
            <Tag color="error" customTextColor={meta.themeColor} animate={true}>{meta.state}</Tag>
            <Tag color="light" customTextColor={meta.themeColor}>{info.incidence_impact}</Tag>
            <Tag color="light" customTextColor={meta.themeColor}>Actualizada hace 30min</Tag>
            {/* <Tag color="success" animate={false}>Cerrada</Tag>
            <Tag color="base" customTextColor={info?.themeColor}>Actualizada hace 30min</Tag> */}
        </header>
        {(expanded) && <div className={style.Content__Body}>
            <Heading size="6"><Icon name="arrow-square-right" type="solid" /> Tipo de incidencia</Heading>
            <Divider size="x-small" /> 
            <Tag color="base">{info.incidence_type}</Tag>

            <Divider size="normal" /> 
            <Heading size="6" color="error"><Icon name="traffic-cone" type="solid" /> Afección</Heading>
            <Divider size="x-small" /> 
            <Tag color="error">{info.incidence_impact}</Tag>

            <Divider size="normal" /> 
            <Heading size="6"><Icon name="clock" type="solid" /> Duración estimada</Heading>
            <Divider size="x-small" /> 
            <Tag color="base">{info.incidence_duration} minutos</Tag>
            
            {/* Itinerary */}
            <Divider size="normal" /> 
            <Heading size="6"><Icon name="route" type="solid" /> Puntos de tu itinerario afectados</Heading>
            <Divider size="x-small" /> 
            <div>
                <Tag>Casa</Tag>
                <Tag color="base"><Icon name="arrow-right" type="solid" /></Tag>
                <Tag>Trabajo</Tag>
            </div>
            {/* Itinerary */}

            {/* Alternative */}
            {info.description && <Fragment>
                <Divider size="normal" /> 
                <Heading size="6" color="base"><Icon name="map-signs" type="solid" /> ¿Qué hacer?</Heading>
                <Divider size="x-small" /> 
                <p>{info.description}</p>
            </Fragment>}
            {/* Alternative */}
        </div>}
    </Fragment>;
}

export default Incidence;
