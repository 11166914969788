import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { UserFilters } from '../../../Models/User';
import { API } from '../../../Utils/API/API';
import { Parser, ParseFilterNames, getModelNameForFilter } from '../../../Utils/Helpers';

import UserContext from '../../../Contexts/User';
import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import HeaderFlow from '../../UI/Header/Flow/Flow';
import Switch from '../../UI/Switch/Switch';
import FooterSection from '../../UI/Footer/Section/Section';
import Divider from '../../UI/Divider/Divider';
import Button from '../../UI/Button/Button';
import Dialog from '../../Dialog/Dialog';
import Link from '../../UI/Link/Link';
import Heading from '../../UI/Heading/Heading';

const Filters = () => {

    const { profile, updateProfileData } = useContext(UserContext);
    const history = useHistory();

    const [saved, setSaved] = useState<boolean>(false);
    const [filters, setFilters] = useState<UserFilters>({
        incidences: true,
        busIncidences: true,
        blocks: true,
        events: true,
        renovations: true
    });

    const saveFilters = async () => {
        try {
            await API.user.updateProfile({ 
                'data': {
                    'communication': {
                        'content_filters': ParseFilterNames({...filters})
                    }
                }
            });
            // update profile
            updateProfileData('filters',{...filters});
            // display saved
            setSaved(true);
            // remove saved on interaction
            document.onclick = () => setSaved(false);
        }
        catch(error) {
            setServiceError(true);
        }
    }

    const setIncidences = (incidences:boolean) => setFilters({...filters, incidences});
    const setBlocks = (blocks:boolean) => setFilters({...filters, blocks});
    const setRenovations = (renovations:boolean) => setFilters({...filters, renovations});
    const setEvents = (events:boolean) => setFilters({...filters, events});
    const setBusIncidences = (busIncidences:boolean) => setFilters({...filters, busIncidences});

    const [screenInfo, setScreenInfo] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);

    const showScreenInfo = (display:boolean) => setScreenInfo(display);
    const showServiceError = (display:boolean) => setServiceError(display);

    useEffect(() => {    
        if(profile.data.filters && profile.data.filters.length > 0 ){

            const profileFilters = {...filters};

            profileFilters.incidences = (profile.data.filters.indexOf(getModelNameForFilter('incidences')) !== -1);
            profileFilters.blocks = (profile.data.filters.indexOf(getModelNameForFilter('blocks')) !== -1);
            profileFilters.renovations = (profile.data.filters.indexOf(getModelNameForFilter('renovations')) !== -1);
            profileFilters.events = (profile.data.filters.indexOf(getModelNameForFilter('events')) !== -1);
            profileFilters.busIncidences = (profile.data.filters.indexOf(getModelNameForFilter('busIncidences')) !== -1);
            
            setFilters(profileFilters);
        }
    },[profile]);

    return <div className="Filters">
        <HeaderFlow 
            title="Tus filtros" 
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="sunset" 
            success={saved}
            icon="bars"
            goTo={() => history.goBack() } /> 
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="medium" />
                <Heading size="3" color="base">Filtra aquí aquel contenido que ver sobre tu mapa de movilidad.</Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6}>
                <Switch key="tr-bus-incidences" label="Desvíos de Guaguas" color="secondary" notify={setBusIncidences} active={filters.busIncidences} />
                <Switch key="tr-events" label="Eventos" color="secondary" notify={setEvents} active={filters.events} />
                <Switch key="tr-incidences" label="Incidencias de tráfico" color="secondary" notify={setIncidences} active={filters.incidences} />
                <Switch key="tr-blocks" label="Cortes" color="secondary" notify={setBlocks} active={filters.blocks} />
                <Switch key="tr-renovations" label="Obras" color="secondary" notify={setRenovations} active={filters.renovations} />
                {/* <Switch key="tr-bus-incidences" label="Incidencias de Guaguas" color="secondary" notify={setBusIncidences} active={filters.busIncidences} /> */}
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={saveFilters} loading={(saved) ? 'success' : 'idle'} color="secondary">Guardar cambios</Button>
                    <Divider size="small" />
                    <Link title="¿Para qué sirven los filtros?" click={() => showScreenInfo(true)}/>
                </GridColumn>
            </Grid>
        </FooterSection>
        {(serviceError) && <Dialog title="Error" body="Error" type="confirmation" close={() => showServiceError(false)} />}
        {(screenInfo) && <Dialog title="Los filtros" body="Cuando configuras tus filtros me ayudas a seleccionar el tipo de contenido que más te puede interesar de tal manera que sólo veas y recibas aquello que realmente sea útil en tu día a día." type="info" close={() => showScreenInfo(false)} />}
    </div>
}

export default Filters;