import React, { useEffect, useState } from 'react';

import H from "@here/maps-api-for-javascript";

import { UserItineraryPoint } from '../../../../../Models/User';
import { API } from '../../../../../Utils/API/API';

import HeaderSection from '../../../../UI/Header/Section/Section';
import FooterSection from '../../../../UI/Footer/Section/Section';
import Grid from '../../../../UI/Grid/Grid';
import GridColumn from '../../../../UI/Grid/Column/Column';
import FormInput from '../../../../UI/Form/Input/Input';
import Divider from '../../../../UI/Divider/Divider';
import Button from '../../../../UI/Button/Button';
import Link from '../../../../UI/Link/Link';
import Heading from '../../../../UI/Heading/Heading';

import style from './New.module.scss';

interface ItineraryProps {
    name?: string;
    address?: string;
    time?: string;
    lat?: number;
    lng?: number;
    cancel: () => void;
    save: (point: UserItineraryPoint) => void;
}

const herePlatform = new H.service.Platform({
    apikey: 'SI1ec2VQf0n7KsaSOTEoARQYZlD-0h0o0buHjSbjcXE'
});

const geocodingService = herePlatform.getSearchService();

const CITY_COUNTRY = " Las Palmas de Gran Canaria (Las Palmas), España";

const New = ({name = '', address = '', time = '', lat = 28.09973, lng = -15.41343, save, cancel}: ItineraryProps) => {

    const [newPoint, setNewPoint] = useState<UserItineraryPoint>({name: name, address: address, time: time, lat: lat, lng: lng});
    const [autoResult, setAutoResult] = useState<any[]>([]);
    const [pointIsValid, setPointAsValid] = useState<boolean>(true);
    const [isAddressSelected, setAddressSelection] = useState<boolean>(false);

    const savePoint = () => save(newPoint);
    const setPointName = (name:string) => setNewPoint({...newPoint, name});
    const setPointTime = (time:string) => setNewPoint({...newPoint, time});

    const setPointAddress = (address:string) => {
        if(address !== newPoint.address) {
            setAddressSelection(false);
            setNewPoint({...newPoint, address});
        }
    }
    // const setPointTransport = (transport:any) => setNewPoint({...newPoint, transport});

    useEffect(() => {
        if(newPoint.address.length > 4 && !isAddressSelected) {
            geocodingService.autosuggest({
                q: newPoint.address + ', Las Palmas de Gran Canaria, España',
                at: '28.09973,-15.41343'
            }, (result:object) => {
                if(result) {
                    
                    let filteredResult: any[] = [];

                    result['items'].forEach((item:any) => {
                        if(item.resultType === 'street' || item.resultType === 'houseNumber') {
                            filteredResult.push(item);
                        }
                    });

                    setAutoResult(filteredResult);
                }
            }, alert);
        }
    },[newPoint.address]);

    const validatePoint = () => {
        
        let isValid = (newPoint.name.length > 0 && newPoint.address.length > 0 && newPoint.time.length > 0);
        
        // update
        setPointAsValid(isValid);
        // submit info
        if(isValid) savePoint();
    }

    const selectAddress = (address:any) => {

        const clonedNewPoint = {...newPoint};

        clonedNewPoint.address = address.title.replace(CITY_COUNTRY,'');
        clonedNewPoint.lat = address.position.lat;
        clonedNewPoint.lng = address.position.lng;

        setAddressSelection(true);
        setNewPoint(clonedNewPoint);
    }
 
    return <div className={style.Point}>
        <HeaderSection title="Punto de itinerario" background="base" color="light" align="center" />
        <Grid>
            <GridColumn size={6}  clear={false}>
            <Divider size="small" />
                <FormInput 
                    type="text"
                    label="Nombre"
                    placeholder="Ej. Mi casa"
                    required={true}
                    sublabel="Para que lo reconozcas sobre el mapa" 
                    valid={pointIsValid}
                    notifyValidation={setPointName}
                    info={false}
                />
            </GridColumn>
            <GridColumn size={6}>
                <Divider size="small" />
                <FormInput 
                    type="text"
                    label="Dirección"
                    placeholder="Ej. Mesa y Lopez 4"
                    required={true}
                    sublabel="Para localizar en el mapa este punto" 
                    valid={pointIsValid}
                    value={newPoint.address}
                    notifyValidation={setPointAddress}
                    info={false}
                />
                {(autoResult.length > 0 && !isAddressSelected) && <div className={style.Autocomplete}>
                    <Heading size="6">Selecciona un resultado...</Heading>
                    <Divider size="x-small" />
                    {autoResult.map((address:any, index:number) => {
                        return <button key={'point-'+index} onClick={() => selectAddress(address)}>{address.title.replace(CITY_COUNTRY,'')}</button>
                    })}
                </div>}
            </GridColumn>
            <Divider size="small" />
            <GridColumn size={6}>
                <FormInput 
                    type="text"
                    label="Hora"
                    placeholder="Ej. 09:00"
                    required={true}
                    sublabel="Para filtrar comunicaciones" 
                    formatter="time"
                    valid={pointIsValid}
                    notifyValidation={setPointTime}
                    info={false}
                />
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={validatePoint} color="base">Guardar punto</Button>
                    <Divider size="small" />
                    <Link title="Cancelar" click={cancel} />
                </GridColumn>
            </Grid>
        </FooterSection>
    </div>
}

export default New;