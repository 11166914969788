import React from 'react';

import { AvatarColorTokens, AvatarFaceTokens } from '../../../Models/Theme';

import Ale from '../../../Assets/Ale/avatar/ale-smile.png';
import style from './Avatar.module.scss';

interface AvatarProps {
    click?: () => void;
    face?: AvatarFaceTokens,
    size?: 'large' | 'medium' | 'normal' | 'small';
    color?: AvatarColorTokens;
    elevation?: boolean;
    loading?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({click, face = 'happy', size = 'normal', color = 'ocean', elevation = true, loading = false}) => {

    const ALT = 'Ale, tu asistente de movilidad';
    const CSS_PREFIX = 'Avatar--';
    const CSS_ELEVATION = (elevation) ? 'elevation' : 'no-elevation';
    const CSS_LOADING = (loading) ? 'loading' : 'no-loading';
    
    let styleConfig: string[] = [style.Avatar,style[CSS_PREFIX+size],style[CSS_PREFIX+color],style[CSS_PREFIX+CSS_ELEVATION],style[CSS_PREFIX+CSS_LOADING]];

    return <div className={styleConfig.join(' ')}>
        {(loading) ? <div className={style.Avatar__loader}></div> : null}
        {(click) ? <button onClick={click} className={style.Avatar__link}><img src={Ale} alt={ALT} /></button> : <img src={Ale} alt={ALT} />}
    </div>
}

export default Avatar;