export const Paths = {
    home: '/',
    signIn: '/acceso',
    recoverPassword: '/acceso/recuperar',
    newPassword: '/acceso/reset/:utoken/:ttoken',
    signUp: '/registro',
    inbox: '/notificaciones',
    onboarding: {
        channels: '/registro/canales',
        transports: '/registro/transporte',
        itinerary: '/registro/itinerario'
    },
    profile: {
        account: '/perfil',
        info: '/perfil/info',
        channels: '/perfil/canales',
        filters: '/perfil/filtros',
        transports: '/perfil/transporte',
        inbox: '/perfil/notificaciones',
        itinerary: '/perfil/itinerario',
        privacy: '/perfil/privacidad'
    },
    content: '/tu-mapa/:modelId/:contentId',
    terms: 'https://lpgcmovilidad.com/politica-de-privacidad',
    about: 'https://lpgcmovilidad.com/acerca-de-ale',
    contact: 'https://lpgcmovilidad.com/contacto'
}

export const ModelNames = () => [
    "desvio-de-linea",
    "event",
    "obra",
    "block",
    "incidencia"
];

export const getModelNameForFilter = (name:string) => {

    const modelNames = {
        'incidences': 'incidencia',
        'busIncidences': 'desvio-de-linea',
        'blocks': 'block',
        'events': 'event',
        'renovations': 'obra'
    }

    return modelNames[name] || name;
}

export const ParseFilterNames = (data:any) => {
    
    const parsedData: string[] = [];
    const foundationNames = {
        'incidences': 'incidencia',
        'busIncidences': 'desvio-de-linea',
        'blocks': 'block',
        'events': 'event',
        'renovations': 'obra'
    }

    for (const key in foundationNames) {
        if (data[key]) parsedData.push(foundationNames[key]);
    }

    return parsedData;
}

export const Parser = (data: any) => {

    const parsedData: string[] = [];

    for (const key in data) {
        if (data[key]) parsedData.push(key);
    }

    return parsedData;
}

export const ProcessDate = (date:string) => {

    if(!date) {
        return undefined;
    }

    const MONTH_NAMES = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    const dateInfo = new Date(date);
    const dateMinutes = dateInfo.getMinutes();
    let parsedDateMinutes = '';

    if(dateMinutes === 0) {
        parsedDateMinutes = '00';
    }
    else if (dateMinutes > 0 && dateMinutes < 10) {
        parsedDateMinutes = `0${dateMinutes}`;
    }
    else {
        parsedDateMinutes = dateMinutes.toString();
    }

    return {
        day: dateInfo.getDate(),
        month: MONTH_NAMES[dateInfo.getMonth()],
        time: `${dateInfo.getHours()}:${parsedDateMinutes}`
    }
}

export const DigitInTwo = (digit:any) => {
    return (digit < 10) ? '0' + digit : digit;
}

export const Timer = (action: any) => {
    setTimeout(() => {
        if (typeof (action) === 'function') action();
    }, 3000);
}

export const ContentColors = (contentType: string) => {
    const content: any = {
        'busStop': '#2a2d5e'
    }
    return content[contentType];
}

export const BusLineColors = (line: string) => {
    const lines: any = {
        '1': '#e2001a',
        '2': '#009ee0',
        '6': '#b966a1',
        '7': '#009036',
        '8': '#009036',
        '9': '#ffdd00',
        '10': '#009036',
        '11': '#009036',
        'x11': '#fe9914',
        '12': '#e2001a',
        '13': '#b966a1',
        '17': '#009036',
        '18': '#009ee0',
        '19': '#009ee0',
        '20': '#009ee0',
        '21': '#009ee0',
        '22': '#009ee0',
        '24': '#009ee0',
        '25': '#ffdd00',
        '26': '#009ee0',
        '32': '#009ee0',
        '33': '#009ee0',
        '35': '#009ee0',
        '41': '#009ee0',
        '44': '#009ee0',
        '45': '#009ee0',
        '46': '#009ee0',
        '47': '#009ee0',
        'x47': '#fe9914',
        '48': '#009ee0',
        '49': '#009ee0',
        '50': '#b966a1',
        '51': '#b966a1',
        '52': '#b966a1',
        '53': '#b966a1',
        '54': '#009036',
        '55': '#b966a1',
        '64': '#b966a1',
        '70': '#009036',
        '80': '#009036',
        '81': '#009ee0',
        '82': '#009036',
        '84': '#009036',
        '91': '#009036',
        'x91': '#fe9914',
        'L1': '#00396b',
        'L2': '#006ab3',
        'L3': '#bce4f6'
    }

    return lines[line] || '#2a2d5e';
}

const UnknownModelConfiguration = {
    color: 'incidence',
    name: 'Incidencia',
    icon: 'exclamation-triangle'
}

export const ModelConfiguration = (model: string) => {
    const stageModels: any = {
        '316': {
            modelName: 'incidence',
            color: 'incidence',
            name: 'Incidencia',
            icon: 'exclamation-triangle'
        },
        '207': {
            modelName: 'event',
            color: 'event',
            name: 'Evento',
            icon: 'calendar-alt'
        },
        '211': {
            modelName: 'obra',
            color: 'renovation',
            name: 'Obra',
            icon: 'user-hard-hat'
        },
        '330': {
            modelName: 'block',
            color: 'renovation',
            name: 'Corte',
            icon: 'traffic-cone'
        },
        '221': {
            modelName: 'desvio-de-linea',
            color: 'incidence',
            name: 'Incidencia en línea',
            icon: 'bus'
        },
        '214': {
            modelName: 'bus',
            color: 'bus',
            name: 'Parada de Guaguas',
            icon: 'bus'
        },
        '342': {
            modelName: 'parking',
            color: 'parking',
            name: 'Parquímetro',
            icon: 'parking-circle'
        },
        '356': {
            modelName: 'bus',
            color: 'bus',
            name: 'Línea de Guaguas',
            icon: 'bus'
        },
        '331': {
            modelName: 'alternative',
            color: 'alternative',
            name: 'Alternative',
            icon: 'directions'
        },
    }
    return stageModels[model] || UnknownModelConfiguration;
}
