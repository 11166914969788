import React from 'react';

import Button from '../../../../UI/Button/Button';
import Icon from '../../../../UI/Icon/Icon';
import style from './Inline.module.scss';

interface InlineProps {
    name: string
    address: string
    time: string
    transport?: string
    listIndex: number
    remove: (listIndex: number) => void
}

const Inline = ({name, address, time, transport, listIndex, remove}: InlineProps) => {
    return <div className={style.Point}>
        <div className={style.Point__decoration}></div>
        <ul>
            <li>
                <h6>{name}</h6>
                <Button click={() => remove(listIndex)} type="minimal">
                    <Icon name="trash-alt" type="solid" color="base" />
                </Button>
            </li>
            <li>{address}</li>
            <li>A las {time}</li>
            {(transport) && <li>{transport}</li>}
        </ul>
    </div>
}

export default Inline;
