import React from 'react';

import Backdrop from '../Backdrop/Backdrop';
import style from './Sheet.module.scss';

const Sheet: React.FC = ({children}) => {
    return <div className={style.Sheet}>
        <Backdrop />
        <div className={style.Sheet__wrapper}>
            {children}
        </div>
    </div>
}

export default Sheet;