import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { UserChannels, UserInfo } from '../../../Models/User';
import { API } from '../../../Utils/API/API';
import { Paths, Parser, ParseFilterNames } from '../../../Utils/Helpers';

import UserContext from '../../../Contexts/User';
import Grid from '../../UI/Grid/Grid';
import GridColumn from '../../UI/Grid/Column/Column';
import HeaderFlow from '../../UI/Header/Flow/Flow';
import Switch from '../../UI/Switch/Switch';
import FooterSection from '../../UI/Footer/Section/Section';
import FormInput from '../../UI/Form/Input/Input';
import Button from '../../UI/Button/Button';
import Divider from '../../UI/Divider/Divider';
import Link from '../../UI/Link/Link';
import Dialog from '../../Dialog/Dialog';
import Heading from '../../UI/Heading/Heading';

interface ChannelsProps {
    showHeader?:boolean
    ctaCopy?:string
}

const Channels: React.FC<ChannelsProps> = ({showHeader = true, ctaCopy = 'Guardar'}) => {

    const history = useHistory();
    
    const { profile, updateProfileData } = useContext(UserContext);
    
    const [channels, setChannels] = useState<UserChannels>({
        email: false,
        whatsapp: false,
        sms: false, 
        push: false, 
        call: false
    });

    const [phone, setPhone] = useState<UserInfo>({phoneNumber: profile.data.phoneNumber} as UserInfo);
    const [phoneBlock, setPhoneBlock] = useState<boolean>(false);
    const [phoneInfo, setPhoneInfo] = useState<boolean>(false);
    const [screenInfo, setScreenInfo] = useState<boolean>(false);
    const [serviceError, setServiceError] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [infoIsValid, setInfoAsValid] = useState<boolean>(true);

    const saveChannels = async () => {
        try {
            
            const updatedProfile = await API.user.updateProfile({ 
                'data': {
                    'phone_number': phone.phoneNumber,
                    'communication': {
                        'channels': Parser({...channels}),
                        'content_filters': [...profile.data.filters] // to force the user to have them during the onboarding flow
                    }
                }
            });
            
            // update profile
            updateProfileData('channels',Parser({...channels}));
            updateProfileData('phoneNumber',updatedProfile.data.phoneNumber);

            // move to transports if user is going through onboarding or display saved
            (!showHeader) ? history.push(Paths.onboarding.transports) : setSaved(true);

            // remove saved on interaction
            document.onclick = () => setSaved(false);
        }
        catch(error) {
            setServiceError(true);
        }
    }

    const displayPhoneNumberField = () => {
        setPhoneBlock(channels.whatsapp || channels.sms || channels.call);
    }

    const setUserPhoneNumber = (phoneNumber:number) => {
        setPhone({...phone, phoneNumber});
    }

    const setEmail = (email:boolean) => {
        setChannels({...channels, email});
    }

    const setWhatsapp = (whatsapp:boolean) => {
        displayPhoneNumberField();
        setChannels({...channels, whatsapp});
    }

    const setSMS = (sms:boolean) => {
        displayPhoneNumberField();
        setChannels({...channels, sms});
    }

    const setCall = (call:boolean) => {
        displayPhoneNumberField();
        setChannels({...channels, call});
    }

    const showPhoneFieldInfo = (display:boolean) => {
        setPhoneInfo(display);
    }

    const showScreenInfo = (display:boolean) => setScreenInfo(display);
    const showServiceError = (display:boolean) => setServiceError(display);

    const validateChannels = () => {

        const isValid = !!(phoneBlock && phone.phoneNumber && phone.phoneNumber.length === 9);

        if(!phoneBlock) {
            saveChannels();
            return;
        }

        // update
        setInfoAsValid(isValid);
        // submit info
        if(isValid) {
            saveChannels();
        }
    }

    useEffect(() => {    
        if(profile.data.channels && profile.data.channels.length > 0){

            const profileChannels = {...channels};

            profileChannels.email = (profile.data.channels.indexOf('email') !== -1);
            profileChannels.whatsapp = (profile.data.channels.indexOf('whatsapp') !== -1);
            profileChannels.sms = (profile.data.channels.indexOf('sms') !== -1);
            profileChannels.call = (profile.data.channels.indexOf('call') !== -1);

            setChannels(profileChannels);
        }

        if(profile.data.phoneNumber && profile.data.phoneNumber.length > 0) {
            setPhone(profile.data.phoneNumber);
        }
    },[profile]);

    return <div className="Channels">
        {(showHeader) && <HeaderFlow 
            title="Tus canales" 
            top="sky" 
            bottom="primary" 
            avatar="happy" 
            avatarColor="sunset" 
            success={saved}
            icon={(showHeader) ? 'bars': 'arrow-left'}
            goTo={() => history.goBack()} />}
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="medium" />
                <Heading size="3" color="base">
                    Elige la forma en la que quieres que me comunique contigo, siempre podrás actualizarlas más tarde...
                </Heading>
                <Divider size="small" />
            </GridColumn>
        </Grid>
        <Grid>
            <GridColumn size={6}>
                <Switch key="ch-email" label="Email" color="secondary" notify={setEmail} active={channels.email} />
                <Switch key="ch-whatsapp" label="Whatsapp" color="secondary" notify={setWhatsapp} active={channels.whatsapp} />
                <Switch key="ch-sms" label="SMS" color="secondary" notify={setSMS} active={channels.sms} />
                <Switch key="ch-call" label="Llamada" color="secondary" notify={setCall} active={channels.call} />
                {(phoneBlock) && <Fragment>
                    <Divider size="medium" />
                    <FormInput 
                        type="tel"
                        label="Tu teléfono móvil"
                        placeholder="Ej. 612345678"
                        required={true}
                        sublabel="Para poder comunicarme contigo..." 
                        info={false}
                        disabled={false}
                        value={profile.data.phoneNumber}
                        valid={infoIsValid}
                        notifyValidation={setUserPhoneNumber}
                        showInfo={showPhoneFieldInfo}
                    />
                </Fragment>}
            </GridColumn>
        </Grid>
        <FooterSection>
            <Grid>
                <GridColumn size={6}>
                    <Button click={validateChannels} color="secondary" loading={(saved) ? 'success' : 'idle'}>{ctaCopy}</Button>
                    <Divider size="small" />
                    <Link title="¿Cómo funcionan las comunicaciones?" click={() => showScreenInfo(true)} />
                </GridColumn>
            </Grid>
        </FooterSection>
        {(serviceError) && <Dialog title="Error" body="Error" type="confirmation" close={() => showServiceError(false)} />}
        {(screenInfo) && <Dialog title="Los canales" body="Son las formas en las que quieres que me comunique contigo, puedes cambiarlas en cualquier momento para que encajen en tu día a día." type="info" close={() => showScreenInfo(false)} />}
        {(phoneInfo) && <Dialog title="¿Por qué tu móvil?" body="Necesito tu teléfono móvil para los canales de WhatsApp, SMS y llamada. Siempre podas controlar tus datos desde el perfil de usuario." type="info" close={() => showPhoneFieldInfo(false)} />}
    </div>
}

export default Channels;