import React, { Fragment } from 'react';

import { NologisContent, BlockContent } from '../../../Models/Content';

import Heading from '../../UI/Heading/Heading';
import Divider from '../../UI/Divider/Divider';
import Tag from '../../UI/Tag/Tag';
import Icon from '../../UI/Icon/Icon';
import Text from '../../UI/Text/Text';
import Calendar from '../../UI/Calendar/Calendar';

import style from '../Content.module.scss';

interface IncidenceProps {
    icon: string
    meta: NologisContent
    info: BlockContent
    expanded?: boolean
}

const Block = ({meta, info, icon, expanded = false}: IncidenceProps) => {

    let isActive = true;

    return <Fragment>
        <header style={{'backgroundColor': meta.themeColor}}>
            <Icon name={icon} /> 
            <Divider size="x-small" /> 
            <Heading size="3" color="neutral">Corte en la ciudad</Heading>
            <Text size="l">{meta.title}</Text>
            {(isActive) && <Fragment>
                <Divider size="small" /> 
                <Tag color="neutral" customTextColor={meta.themeColor} size="small" animate={true}>{meta.state}</Tag>  
            </Fragment>}
        </header>
        {(expanded) && <div className={style.Content__Body}>
            {/* Timeframe */}
            {info.start_date_and_time && <Fragment>
                <Heading size="3" color="base">
                    <Icon name="clock" type="solid" /> Duración estimada</Heading>
                <Divider size="small" /> 
                <Calendar from={info.start_date_and_time} to={info.end_date_and_time} />
            </Fragment>}
            {/* Span */}
            {info.block_initial_address && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3" color="base">
                    <Icon name="traffic-cone" type="solid" /> Afecta al tramo entre...</Heading>
                <Divider size="x-small" /> 
                <Tag color="base"><Icon name="arrow-down" type="solid" /></Tag> <Tag color="base">{info.block_initial_address}</Tag>
                <Divider size="x-small" /> 
                <Tag color="base"><Icon name="circle" type="solid" /></Tag> <Tag color="base">{info.block_final_address}</Tag>
            </Fragment>}
            {/* Itinerary */}
            {/* Alternative */}
            {info.description && <Fragment>
                <Divider size="normal" /> 
                <Heading size="3" color="base">
                    <Icon name="map-signs" type="solid" /> Información adicional
                </Heading>
                <Divider size="x-small" /> 
                <Text size="l">{info.description}</Text>
            </Fragment>}
        </div>}
    </Fragment>;
}

export default Block;
