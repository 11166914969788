import React from 'react';

import { Link } from 'react-router-dom';
import { Config } from '../../Utils/Config';
import { Paths } from '../../Utils/Helpers';

import Heading from '../UI/Heading/Heading';

import style from './Navigation.module.scss';

interface NavigationProps{
    open?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({open = true, children}) => {

    const navigationState = (!open) ? [style.Screen] : [style.Screen,style.Screen__off];

    return <div className={style.Navigation}>
        <div className={style.Navigation__drawer}>
            <nav>
                <a href={Paths.about} target="_blank" rel="noopener noreferrer">
                    <Heading size="4">Acerca de mi</Heading>
                </a>
                <a href={Paths.terms} target="_blank" rel="noopener noreferrer">
                    <Heading size="4">Términos y condiciones</Heading>
                </a>
                <a href={Paths.contact} target="_blank" rel="noopener noreferrer">
                    <Heading size="4">Contacto</Heading>
                </a>
                <Link to={Paths.profile.account}>
                    <Heading size="4">Accede a tu área</Heading>
                </Link>
            </nav>
            <span className={style.legal}>{Config.webVersion}</span>
        </div>
        <div className={navigationState.join(' ')}>
            {children}
        </div>
    </div>;
}

export default Navigation;