import React from 'react';
import { ColorTokens } from '../../Models/Theme';

import Grid from '../UI/Grid/Grid';
import GridColumn from '../UI/Grid/Column/Column';
import Divider from '../UI/Divider/Divider';

import style from './Feedback.module.scss';

interface props {
    color: ColorTokens;
}

const Feedback: React.FC<props> = props => {
    return <div className={style.Feedback}>
        <Grid clearSide="bottom">
            <GridColumn size={3}>
                <Divider size="small" />
                {props.children}
            </GridColumn>
        </Grid>
    </div>;
}

export default Feedback;