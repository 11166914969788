import React from 'react';

import { ColorTokens } from '../../../Models/Theme';

import style from './Icon.module.scss';

interface IconProps {
    name: string
    type?: 'outline' | 'solid' | 'social'
    color?: ColorTokens
}

const Icon = ({name, type = 'outline', color = 'light'}:IconProps) => {

    let faType = 'far';
    const styleConfig: string[] = [style.Icon,style['Icon--'+color]];

    switch(type) {
        case 'outline':
            faType = 'far';
            break;
        case 'solid':
            faType = 'fas';
            break;
        case 'social':
            faType = 'fab';
            break;
    }

    return <div className={styleConfig.join(' ')}>
        <i className={faType+' fa-'+name}></i>
    </div>;
}

export default Icon;