import React, { Fragment } from 'react';
import style from './Calendar.module.scss';
import { ProcessDate } from '../../../Utils/Helpers';

import Heading from '../Heading/Heading';
import Icon from '../Icon/Icon';

interface CalendarProps {
    from: string;
    to?: string
}

const Calendar = ({from, to}: CalendarProps) => {

    const fromDetails = ProcessDate(from);
    const toDetails = ProcessDate(to);
    
    return <div className={style.Calendar}>
        <div className={style.Calendar__Date}>
            <div>{fromDetails.month}</div>
            <div>
                <div>
                    <Heading size="4">{fromDetails.day}</Heading>
                </div>
                <div>
                    <Heading size="4">{fromDetails.time}</Heading>
                </div>
            </div>
        </div>
        {(to) && <Fragment>
            <div className={style.Calendar__Arrow}>
                <Icon name="arrow-right" type="solid" />
            </div>
            <div className={style.Calendar__Date}>
                <div>{toDetails.month}</div>
                <div>
                    <div>
                        <Heading size="4">{toDetails.day}</Heading>
                    </div>
                    <div>
                        <Heading size="4">{toDetails.time}</Heading>
                    </div>
                </div>
            </div>
        </Fragment>}
    </div>
}

export default Calendar;