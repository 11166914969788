import React from 'react';
import { ColorTokens } from '../../../../Models/Theme';
import style from './Section.module.scss';

import Grid from '../../../UI/Grid/Grid';
import GridColumn from '../../../UI/Grid/Column/Column';
import Heading from '../../../UI/Heading/Heading';

interface SectionProps {
    title?: string;
    subtitle?: string;
    align?: 'default' | 'center';
    color?: ColorTokens;
    background?: ColorTokens;
}

const Section = ({title, subtitle, align, color, background}: SectionProps) => {

    const CSS_PREFIX = 'Section--';
    const styleConfig: string[] = [style.Section,style[CSS_PREFIX+color],style[CSS_PREFIX+background]];

    return <div className={styleConfig.join(' ')}>
        <Grid>
            <GridColumn size={6}>
                <Heading size="3" color={color}>{title}</Heading>
                {(subtitle) && <p>{subtitle}</p>}
            </GridColumn>
        </Grid>
    </div>
}

export default Section;