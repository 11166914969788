import React, { FC, Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ContentColorTokens } from '../../Models/Theme';
import { ModelConfiguration, Paths } from '../../Utils/Helpers';

import Incidence from './Incidence/Incidence';
import BusIncidence from './BusIncidence/BusIncidence';
import Renovation from './Renovation/Renovation';
import Block from './Block/Block';
import Event from './Event/Event';
import BusStop from './BusStop/BusStop';
import ParkingMeter from './ParkingMeter/ParkingMeter';
import BusLine from './BusLine/BusLine';
import Alternative from './Alternative/Alternative';

import Button from '../UI/Button/Button';
import Link from '../UI/Link/Link';
import Icon from '../UI/Icon/Icon';
import CardLoader from '../Loader/Card/Card';

import style from './Content.module.scss';
import { useContentDetails } from '../../hooks/useContentDetails';
import { HighlightDataEntity, MapActionDispatcher, CenterAtDataEntity } from '@nologis/maps';

interface ContentProps {
    model: string;
    id: string;
    type?: ContentColorTokens;
}

const CSS_PREFIX: string = 'Content--';

const MapHighlightContent = (id: string, model: string) => {
    MapActionDispatcher.dispatch(HighlightDataEntity.build({
        contentId: id,
        clientModel: model,
        center: true,
        only: true
    }));
}

const Content: FC<ContentProps> = ({ model, id }) => {

    const { content, isLoading } = useContentDetails(model, id);

    let contentDetails: any = null;
    let displayInfoButton: boolean = true;
    
    const history = useHistory();

    const modelConfiguration = ModelConfiguration(model);
    const [full, setFull] = useState<boolean>(false);
    const [metas, setMetas] = useState<any>({});

    let styleConfig: string[] = [style.Content, style[CSS_PREFIX + modelConfiguration.color]];

    const shareContent = () => {
        console.log('share');
    }

    useEffect(() => {
        if (content) {
            MapHighlightContent(id, modelConfiguration.modelName);
            setMetas({
                id: content.id,
                state: content.nologis_state || 'abierta',
                title: content.title,
                description: content.description,
                created_at: content.nologis_created_at,
                tags: content.nologis_tags,
                themeColor: content.nologis_theme?.marker.DEFAULT?.marker.icon.fillColor,
            })
        }
    }, [content])

    if (content) {
        switch (model) {
            case '316': // traffic incidence
                contentDetails = <Incidence icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '221': // bus incidence: stage 353
                contentDetails = <BusIncidence icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '211': // renovation: stage 313 
                contentDetails = <Renovation icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '330': // block
                contentDetails = <Block icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '207': // event: stage 315
                contentDetails = <Event icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '355': // line detour
                break;
            case '356': // bus line: stage 356
                displayInfoButton = false;
                contentDetails = <BusLine icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '342': // parking meter
                displayInfoButton = false;
                contentDetails = <ParkingMeter icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '331': // alternative
                contentDetails = <Alternative icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
            case '214': // bus stop: stage 329
                displayInfoButton = false;
                contentDetails = <BusStop icon={modelConfiguration.icon} meta={metas} info={content} expanded={full}/>
                break;
        }
    }

    return <div className={styleConfig.join(' ')}>
        {(content) ?
            <div className={style.Content__Wrapper}>
                <div className={style.Content__Close}>
                    <Button color="base" type="minimal" elevation={false} click={() => history.push(Paths.home)}>
                        <Icon name="times"/>
                    </Button>
                </div>
                {contentDetails}
                {(!full && displayInfoButton) && <div className={style.Content__Actions}>
                    <Button color="base" elevation={true} click={() => setFull(true)}>
                        <Icon name="plus"/>Ampliar información
                    </Button>
                </div>}
                {(full && displayInfoButton) && <Fragment>
                    <div className={style.Content__Actions}>
                        <Button color="base" elevation={true} click={() => setFull(false)}>
                            <Icon name="minus"/>Ocultar información
                        </Button>
                    </div> 
                </Fragment>}
            </div>
            : <div className={style.Content__Wrapper}>
                <CardLoader/>
            </div>}
    </div>
}

export default Content;
