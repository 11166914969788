import React, { Fragment } from 'react';
import { isBreakOrContinueStatement } from 'typescript';
import { ButtonColorTokens, ButtonSizeTokens, ButtonTypeTokens, IconTypeTokens } from '../../../Models/Theme';
import Icon from '../../UI/Icon/Icon';
import style from './Button.module.scss';

interface ButtonProps {
    click: () => void
    state?: string
    type?: ButtonTypeTokens
    color?: ButtonColorTokens
    elevation?: boolean
    size?: ButtonSizeTokens
    icon?: string
    iconType?: IconTypeTokens
    disabled?: boolean
    loading?: 'idle' | 'loading' | 'error' | 'success'
}

const CSS_PREFIX:string = 'Button--';

const Button: React.FC<ButtonProps> = ({click, state, type = 'regular', color = 'base', elevation = true, size = 'medium', icon, iconType, loading = 'idle', children}) => {

    const isElevated: string = (elevation) ? 'elevation' : 'no-elevation';

    let styleConfig: string[] = [style.Button,style[CSS_PREFIX+type],style[CSS_PREFIX+color],style[CSS_PREFIX+isElevated],style[CSS_PREFIX+loading]];
    let statusLayout: any = undefined;
    
    switch(loading) {
        case 'idle':
            if(icon) statusLayout = <Icon name={icon} type={iconType} />
            break;
        case 'loading':
            statusLayout = <Fragment>
                <Icon name="spinner-third" type={iconType} />
                Un momento...
            </Fragment>
            break;
        case 'error':
            statusLayout = <Fragment>
                <Icon name="exclamation-circle" type={iconType} />
                Hubo un error
            </Fragment>
            break;
        case 'success':
            statusLayout = <Fragment>
                <Icon name="check-circle" type={iconType} />
                Cambios guardados
            </Fragment>
            break;
    }

    return <button className={styleConfig.join(' ')} onClick={click} disabled={(loading !== 'idle')}>
        <span>
            {statusLayout}
            {(loading === 'idle') && children}
        </span>
    </button>;
}

export default Button;